import { z } from 'zod';
import {
  AddressApiSchema,
  CreateAddressPayloadSchema,
} from './address-from-api';

const BaseApiSchema = z.object({
  id: z.string().optional(),
  created_at: z.nullable(z.string()).optional(),
  updated_at: z.nullable(z.string()).optional(),
  deleted_at: z.nullable(z.string()).optional(),
});

export const AgentApiSchema = BaseApiSchema.extend({
  role: z.nullable(
    z.union([z.literal('admin'), z.literal('member'), z.literal('developper')]),
  ),
  email: z.nullable(z.string().email()),
  first_name: z.nullable(z.string()).optional(),
  last_name: z.nullable(z.string()).optional(),
  api_token: z.nullable(z.string()).optional(),
  metadata: z.nullable(z.record(z.unknown())).optional(),
});

export const CompanyApiSchema = BaseApiSchema.extend({
  name: z.nullable(z.string()),
  code: z.nullable(z.string()),
  logo: z.nullable(z.string()).optional(),
  payment_terms: z.nullable(z.string()).optional(),
  registration_number: z.nullable(z.string()).optional(),
  vat_number: z.nullable(z.string()).optional(),
  metadata: z.nullable(z.record(z.unknown())).optional(),
  parent_company_id: z.nullable(z.string()).optional(),
  payment_currency_code: z.nullable(z.string()),
  franco: z.nullable(z.number()).optional(),
  agent: z.nullable(AgentApiSchema),
  billing_address: z.nullable(AddressApiSchema),
  shipping_addresses: z.nullable(z.array(AddressApiSchema)),
  region_id: z.nullable(z.string()).optional(),
});

export const CreateCompanyPayloadSchema = z.object({
  email: z.string().email(),
  first_name: z.string(),
  last_name: z.string(),
  phone: z.string().optional(),
  company_name: z.string(),
  registration_number: z.string().optional(),
  vat_number: z.string().optional(),
  shipping_address: CreateAddressPayloadSchema,
  billing_address: CreateAddressPayloadSchema,
});

export type AgentFromApi = z.infer<typeof AgentApiSchema>;
export type CompanyFromApi = z.infer<typeof CompanyApiSchema>;
export type CreateCompanyPayload = z.infer<typeof CreateCompanyPayloadSchema>;
