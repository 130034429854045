import { AdjustmentFromApi } from '@lib/models/api/line-item-from-api';
import {
  DiscountFromApi,
  RuleFromApi,
} from '../../models/api/discount-from-api';
import { IDiscount } from '../../models/view-models/discount';

export const buildDiscount = (
  discount: DiscountFromApi,
  adjustments?: AdjustmentFromApi[],
): IDiscount => {
  const { rule } = discount;
  return {
    id: discount.id,
    code: discount.code,
    description: rule.description,
    value: buildValue(rule, adjustments),
    allocation: rule.allocation,
    type: rule.type,
    tags: buildTags(rule),
    isAutoApplied: (discount.metadata?.auto_apply as boolean) ?? false,
    minItems: discount.metadata?.min_items as number,
    maxItems: discount.metadata?.max_items as number,
    endDate: discount.ends_at ? new Date(discount.ends_at) : undefined,
  };
};

const buildValue = (rule: RuleFromApi, adjustments?: AdjustmentFromApi[]) => {
  switch (rule.type) {
    case 'percentage':
      return rule.value;
    case 'fixed':
      return rule.value / 100;
    case 'complement': {
      if (!adjustments) {
        return rule.value / 100;
      }
      return adjustments.reduce((acc, a) => acc + (a.amount || 0), 0) / 100;
    }
    default:
      return null;
  }
};

const buildTags = (rule: RuleFromApi) => {
  if (
    rule.allocation === 'item' &&
    rule.conditions?.some((c) => c.type === 'product_tags')
  ) {
    return rule.conditions.flatMap((c) =>
      (c.product_tags || []).map((t) => t.value),
    );
  }
  return undefined;
};
