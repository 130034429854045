import { QueryFunction } from '@tanstack/react-query';
import { catalogRequest } from '../config';
import { OrderApiSchema, OrderFromApi } from '../models/api/order-from-api';
import { IStandardApiResponse } from '../models/api/standard-api-response';
import axios, { AxiosResponse } from 'axios';
import error from 'next/error';

type OrderQueryParams = {
  fields: string;
  expand: string;
  limit: number;
  searchId: string | null;
  statuses: OrderFromApi['status'][] | null;
};

export type OrdersResponse = IStandardApiResponse & { orders: OrderFromApi[] };
export type OrderResponse = IStandardApiResponse & { order: OrderFromApi };

export const getCustomerOrders: QueryFunction<
  OrdersResponse,
  ['orders', OrderQueryParams]
> = async ({ queryKey, pageParam }) => {
  const path = 'store/customers/me/company/orders';

  const params: Record<string, unknown> = {
    limit: queryKey[1].limit,
    offset: pageParam || 0,
    fields: queryKey[1].fields,
    expand: queryKey[1].expand,
    external_id: queryKey[1].searchId || undefined,
    status: queryKey[1].statuses || undefined,
  };

  const { data: response } = await catalogRequest<OrdersResponse>(
    'get',
    path,
    undefined,
    params,
  );
  if (response.orders.length > 0 || !queryKey[1].searchId) {
    return response;
  }
  delete params.external_id;
  params.seller_order_id = queryKey[1].searchId;

  const { data: response2 } = await catalogRequest<OrdersResponse>(
    'get',
    path,
    undefined,
    params,
  );

  return response2;
};

export const getCustomerOrderById: QueryFunction<
  OrderFromApi,
  ['order', string]
> = async ({ queryKey }) => {
  const expand =
    'shipping_address,billing_address,items,shipping_methods,region,payments,discounts,items.variant,items.variant.options,items.variant.options.option';
  const path = `store/orders/${queryKey[1]}?expand=${expand}`;
  const response = await catalogRequest<{ order: OrderFromApi }>(
    'get',
    path,
  ).then((res) => res.data);
  const parsedResponse = OrderApiSchema.parse(response.order);
  return parsedResponse;
};

export const tryGetOrderByCartId = async (
  cartId: string,
  attempt = 1,
): Promise<OrderFromApi | undefined> => {
  try {
    const path = `store/orders/cart/${cartId}`;
    const response = await catalogRequest<{ order: OrderFromApi }>(
      'get',
      path,
    ).then((res) => res.data);
    const parsedResponse = OrderApiSchema.parse(response.order);
    return parsedResponse;
  } catch (e) {
    if (
      axios.isAxiosError(error) &&
      error.response.status === 404 &&
      attempt < 5
    ) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      return tryGetOrderByCartId(cartId, attempt + 1);
    }
    console.warn('Error fetching order by cart id', cartId, e);
    return undefined;
  }
};

export const uploadOrder = async (formData: FormData) => {
  const url = process.env.NEXT_PUBLIC_UPLOAD_ORDER_URL;
  const apiKey = process.env.NEXT_PUBLIC_UPLOAD_ORDER_API_KEY;
  if (!url || !apiKey) {
    console.error({ error: 'Missing environment variables' });
    return;
  }
  try {
    const response = await axios.post(url, formData, {
      headers: {
        'X-API-KEY': apiKey,
      },
    });
    return response;
  } catch (error) {
    console.error(error.response ? error.response.data : error.message);
    return error;
  }
};

export const confirmOrder = async (orderId: string): Promise<OrderFromApi> => {
  const apiResponse: AxiosResponse<{
    order: OrderFromApi;
  }> = await catalogRequest('post', `store/orders/${orderId}/confirm`);
  const parsedResponse = OrderApiSchema.parse(apiResponse.data.order);
  return parsedResponse;
};
