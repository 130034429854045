import { DiscountFromApi } from '@lib/models/api/discount-from-api';
import { CustomerFromApi } from '../../models/api/customer-from-api';
import { ICatalogCustomer } from '../../models/view-models/customer';
import { buildFavorite } from '../favorite';
import { buildDiscount } from '../discount';

export const buildCustomer = (customer: CustomerFromApi): ICatalogCustomer => {
  return {
    id: customer.id,
    firstName: customer.first_name,
    lastName: customer.last_name,
    email: customer.email,
    phone: customer.phone,
    hasAccount: customer.has_account,
    metadata: customer.metadata,
    favoriteLists:
      customer.favorite_lists?.map((list) => buildFavorite(list)) || [],
    discounts: customer.discounts ? buildDiscounts(customer.discounts) : [],
    originalEmail: customer.original_user?.email,
  };
};

const buildDiscounts = (discounts: DiscountFromApi[]) => {
  return discounts
    .filter(
      (
        d, // filter discount with not managed conditions
      ) =>
        (d.rule.conditions || []).every(
          (c) =>
            ![
              'products',
              'product_types',
              'product_collections',
              'product_variants',
              'product_categories',
            ].includes(c.type),
        ),
    )
    .map((d) => buildDiscount(d))
    .sort((a, b) => a.value - b.value);
};
