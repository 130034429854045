import React, {
  createContext,
  Dispatch,
  useCallback,
  useContext,
  useReducer,
} from 'react';
import {
  FavoriteActionsType,
  favoriteReducer,
  FavoriteState,
} from './favorite-state';

interface FavoriteContext {
  state: FavoriteState;
  dispatch: Dispatch<FavoriteActionsType>;
  isProductInFavoriteList: (variantId: string) => boolean;
  isProductInMultipleFavoriteLists: (variantId: string) => boolean;
}

const initialState: FavoriteState = {
  favoriteProducts: new Set<string>(null),
  favoriteLists: null,
};

export const FavoriteContext = createContext<FavoriteContext>(null);

export const useFavorites = () => useContext(FavoriteContext);

export const FavoriteProvider = ({ children }) => {
  const [state, dispatch] = useReducer(favoriteReducer, initialState);

  const isProductInFavoriteList = useCallback(
    (variantId: string): boolean => state.favoriteProducts.has(variantId),
    [state],
  );

  const isProductInMultipleFavoriteLists = useCallback(
    (variantId: string): boolean => {
      if (!state.favoriteProducts.has(variantId)) {
        return false;
      }
      let count = 0;
      for (const list of Object.values(state.favoriteLists)) {
        if (list.includes(variantId)) {
          count++;
        }
        if (count > 1) {
          return true;
        }
      }
      return false;
    },
    [state],
  );

  return (
    <FavoriteContext.Provider
      value={{
        state,
        dispatch,
        isProductInFavoriteList,
        isProductInMultipleFavoriteLists,
      }}
    >
      {children}
    </FavoriteContext.Provider>
  );
};
