import { Product } from '@medusajs/medusa';
import { NextPage } from 'next';
import { AppProps } from 'next/app';
import { ReactElement, ReactNode } from 'react';

export type CollectionData = {
  id: string;
  title: string;
};

export type FeaturedProduct = {
  id: string;
  title: string;
  handle: string;
  thumbnail?: string;
};

export type StoreNavData = {
  collections: CollectionData[];
  hasMoreCollections: boolean;
  featuredProducts: Product[];
};

// page props for store pages (products and collection pages)
export type StoreProps<T> = {
  page: {
    data: T;
  };
};

// page props for non-store pages (home, about, contact, etc)
export type SiteProps = {
  site: {
    navData: StoreNavData;
  };
};

export type PrefetchedPageProps = {
  notFound: boolean;
};

// For pages with nested layouts
export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout: (page: ReactElement) => ReactNode;
};

export type AppPropsWithLayout<P = {}, IP = P> = AppProps<P> & {
  Component: NextPageWithLayout<P, IP>;
};

export type ProductPreviewType = {
  id: string;
  title: string;
  handle: string | null;
  thumbnail: string | null;
  price?: {
    calculated_price: string;
    original_price: string;
    difference: string;
    price_type: 'default' | 'sale';
  };
};

export type InfiniteProductPage = {
  response: {
    products: Product[];
    count: number;
  };
};

export type Language = {
  label: string;
  shortLabel: string;
  countryCode: string;
};

export enum OptionType {
  ANGLE = 'angle',
  AREA = 'area',
  DATA = 'data',
  ENERGY = 'energy',
  FORCE = 'force',
  LENGTH = 'length',
  MASS = 'mass',
  POWER = 'power',
  PRESSURE = 'pressure',
  TEMPERATURE = 'temperature',
  TIME = 'time',
  VOLUME = 'volume',
  NUMBER = 'number',
  COLOR = 'color',
}

export type DropdownselectOption = {
  id: string;
  value: string;
  displayedValue?: JSX.Element | string;
};

export class DuplicateOrderError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'DuplicateOrderError';
  }
}
