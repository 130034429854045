import { z } from 'zod';
import { VariantApiSchema } from './product-from-api';

const BaseApiSchema = z.object({
  id: z.string().optional(),
  created_at: z.nullable(z.string()).optional(),
  updated_at: z.nullable(z.string()).optional(),
});

export const AdjustmentApiSchema = z.object({
  amount: z.nullable(z.number()),
  discount_id: z.nullable(z.string()).optional(),
  item_id: z.nullable(z.string()).optional(),
});

export const LineItemApiSchema = BaseApiSchema.extend({
  rank: z.number(),
  allow_discount: z.nullable(z.boolean()).optional(),
  cart_id: z.nullable(z.string()).optional(),
  description: z.nullable(z.string()).optional(),
  discount_total: z.nullable(z.number()).optional(),
  fulfilled_quantity: z.nullable(z.number()).optional(),
  has_shipping: z.nullable(z.boolean()).optional(),
  is_giftcard: z.nullable(z.boolean()).optional(),
  is_return: z.nullable(z.boolean()).optional(),
  metadata: z.nullable(z.record(z.unknown())).optional(),
  order_edit_id: z.nullable(z.string()).optional(),
  order_id: z.nullable(z.string()).optional(),
  original_item_id: z.nullable(z.string()).optional(),
  original_tax_total: z.nullable(z.number()).optional(),
  original_total: z.nullable(z.number()).optional(),
  quantity: z.nullable(z.number()).optional(),
  raw_discount_total: z.nullable(z.number()).optional(),
  returned_quantity: z.nullable(z.number()).optional(),
  shipped_quantity: z.nullable(z.number()).optional(),
  should_merge: z.nullable(z.boolean()).optional(),
  subtotal: z.nullable(z.number()).optional(),
  tax_total: z.nullable(z.number()).optional(),
  swap_id: z.nullable(z.string()).optional(),
  total: z.nullable(z.number()).optional(),
  unit_price: z.nullable(z.number()).optional(),
  thumbnail: z.nullable(z.string().url()).optional(),
  title: z.nullable(z.string()).optional(),
  variant_id: z.nullable(z.string()).optional(),
  order: z
    .nullable(
      z.object({
        seller_order_id: z.nullable(z.string()).optional(),
        external_id: z.nullable(z.string()).optional(),
        display_id: z.nullable(z.number()).optional(),
      }),
    )
    .optional(),
  variant: z.nullable(VariantApiSchema).optional(),
  tax_lines: z
    .nullable(
      z.array(
        z.object({
          rate: z.nullable(z.number()).optional(),
          code: z.nullable(z.string()).optional(),
          item_id: z.nullable(z.string()).optional(),
          name: z.nullable(z.string()).optional(),
        }),
      ),
    )
    .optional(),
  adjustments: z.nullable(z.array(AdjustmentApiSchema)).optional(),
});

export type AdjustmentFromApi = z.infer<typeof AdjustmentApiSchema>;
export type LineItemFromApi = z.infer<typeof LineItemApiSchema>;
