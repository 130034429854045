import { z } from 'zod';
import { useCatalogTranslation } from './use-catalog-translations';

export const useLoginForm = () => {
  const { ct } = useCatalogTranslation(['common']);
  const loginSchema = z.object({
    email: z
      .string()
      .email({ message: ct('Login.emailError', 'Adresse email invalide') }),
    password: z.string().min(8, {
      message: ct(
        'Login.passwordNotValid',
        'Le mot de passe doit contenir au moins 8 caractères',
      ),
    }),
  });

  const sendEmailSchema = z.object({
    email: z
      .string()
      .email({ message: ct('Login.emailError', 'Adresse email invalide') }),
  });

  const definePasswordSchema = z
    .object({
      password: z.string().min(8, { message: ct('Login.passwordNotValid') }),
      confirmPassword: z
        .string()
        .min(8, { message: ct('Login.passwordNotValid') }),
    })
    .refine((data) => data.password === data.confirmPassword, {
      message: ct(
        'Login.passwordDontMatch',
        'Les mots de passe doivent être identiques',
      ),
      path: ['confirmPassword'],
    });

  return { loginSchema, sendEmailSchema, definePasswordSchema } as const;
};
