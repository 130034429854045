import { z } from 'zod';
import { VariantApiSchema } from './product-from-api';

const BaseApiSchema = z.object({
  id: z.string().optional(),
  created_at: z.nullable(z.string()).optional(),
  updated_at: z.nullable(z.string()).optional(),
  deleted_at: z.nullable(z.string()).optional(),
});

const FavoriteItemApiSchema = BaseApiSchema.extend({
  variant_id: z.string(),
  favorite_list_id: z.string().optional(),
  variant: z.nullable(VariantApiSchema).optional(),
});

export const FavoriteApiSchema = BaseApiSchema.extend({
  name: z.string(),
  customer_id: z.string().optional(),
  items: z.array(FavoriteItemApiSchema).optional(),
});

export type FavoriteItemFromApi = z.infer<typeof FavoriteItemApiSchema>;
export type FavoriteFromApi = z.infer<typeof FavoriteApiSchema>;
