import { QueryFunction } from '@tanstack/react-query';
import { z } from 'zod';
import { catalogRequest } from '../config';
import {
  CompanyApiSchema,
  CompanyFromApi,
  CreateCompanyPayload,
} from '../models/api/company-from-api';
import {
  CustomerApiSchema,
  CustomerFromApi,
} from '../models/api/customer-from-api';
import {
  ProductApiSchema,
  ProductFromApi,
} from '../models/api/product-from-api';
import { ICatalogCustomer } from '../models/view-models/customer';
import { buildCustomer } from '../utils/customer';

export type CustomerProductQueryParams = {
  region: string;
  currency: string;
  fields?: string;
  expand?: string;
  limit?: number;
};

export const getCustomerAccount = async (): Promise<ICatalogCustomer> => {
  return await catalogRequest<{ customer: CustomerFromApi }>(
    'get',
    'store/customers/me',
  ).then((res) => {
    const { customer } = res.data;

    const parsedCustomer = CustomerApiSchema.parse(customer);

    return buildCustomer(parsedCustomer);
  });
};

export const getCustomerCompany: QueryFunction<
  CompanyFromApi,
  ['customerCompany']
> = async () => {
  const response = await catalogRequest<{ company: CompanyFromApi }>(
    'get',
    'store/customers/me/company',
  ).then((res) => res.data);

  const parsedCompany = CompanyApiSchema.parse(response.company);
  return parsedCompany;
};

export const getCustomerVariants = async (
  region: string,
  currency: string,
): Promise<unknown> => {
  return await catalogRequest(
    'get',
    `store/variants?region_id=${region}&currency_code=${currency}`,
  ).then((res) => res.data);
};

export const getCustomerProducts: QueryFunction<
  ProductFromApi[],
  ['customerProducts', CustomerProductQueryParams]
> = async ({ queryKey }) => {
  const { region, currency, fields, expand, limit } = queryKey[1];

  let url = `store/products?region_id=${region}&currency_code=${currency}`;

  // if fields is null it means caller wants to all fields
  if (fields) {
    url += `&fields=${fields}`;
  }
  // if expand is null it means caller wants to all relations
  if (expand) {
    url += `&expand=${expand}`;
  }

  if (limit) {
    url += `&limit=${limit}`;
  }

  return await catalogRequest<{ products: ProductFromApi[] }>('get', url).then(
    (res) => {
      const { products } = res.data;
      const parsedProducts = z.array(ProductApiSchema).parse(products);
      return parsedProducts;
    },
  );
};

export const getCustomerSettings = async (): Promise<unknown> => {
  return await catalogRequest('get', 'store/settings?limit=1000').then(
    (res) => res.data,
  );
};

export const authenticate = async (payload: {
  email: string;
  password: string;
}): Promise<ICatalogCustomer | undefined> => {
  try {
    const response = await catalogRequest<{ customer: CustomerFromApi }>(
      'post',
      'store/auth',
      payload,
    );
    if (!response) {
      throw new Error('No response');
    }
    const data = response.data;
    return buildCustomer(data.customer);
  } catch (error) {
    console.error('Error authenticating', error.response?.status, { error });
    return undefined;
  }
};

export const logout = async (): Promise<unknown> => {
  return await catalogRequest('delete', 'store/auth');
};

export const addNewCustomerAndCompany = async ({
  payload,
}: {
  payload: CreateCompanyPayload;
}) => {
  const path = '/store/customers/register';
  return await catalogRequest('post', path, payload).then((res) => res.data);
};

export const resetCustomerPassword = async (payload: {
  password: string;
  email: string;
  token: string;
}): Promise<CustomerFromApi> => {
  const path = '/store/customers/password-reset';
  try {
    const response = await catalogRequest<{ customer: CustomerFromApi }>(
      'post',
      path,
      payload,
    );
    const { customer } = response.data;
    const parsedCustomer = CustomerApiSchema.parse(customer);
    return parsedCustomer;
  } catch (error) {
    console.error('Failed to update customer', error);
    return undefined;
  }
};

export const requestPasswordReset = async (email: string) => {
  const payload = { email };
  const path = '/store/customers/password-token';
  try {
    await catalogRequest('post', path, payload);
  } catch (error) {
    console.error('failed to reset password', error);
  }
};

export const checkIfEmailExists = async (email: string) => {
  const path = `/store/auth/${email}`;
  try {
    const response = await catalogRequest<{ exists: boolean }>('get', path);
    if (response) {
      return response.data.exists;
    }
    throw `failed to check if email ${email} exists`;
  } catch (error) {
    console.error(`failed to check if email ${email} exists`, error);
    return false;
  }
};
