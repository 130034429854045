// change hex color in rgb and return css variable
export const getRGBColor = (hex: string, type: string): string => {
  // rgb values
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `--color-${type}: ${r}, ${g}, ${b};`;
};

// Determine if the color is light or dark
export const getAccessibleColor = (hex: string) => {
  // rgb values
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? '#000000' : '#FFFFFF';
};
