import { Dialog, Transition } from '@headlessui/react';
import { ModalProvider, useModal } from '@lib/context/modal-context';
import clsx from 'clsx';
import { Cancel } from 'iconoir-react';
import React, { Fragment } from 'react';

type ModalProps = {
  isOpen: boolean;
  close: () => void;
  size?: 'mobile' | 'small' | 'medium' | 'large';
  children?: React.ReactNode;
};

type LiteProps = {
  children?: React.ReactNode;
  size?: 'mobile' | 'small' | 'medium' | 'large';
};

const Modal: React.FC<ModalProps> & {
  Title: React.FC<LiteProps>;
  Description: React.FC<LiteProps>;
  Body: React.FC<LiteProps>;
  Footer: React.FC<LiteProps>;
} = ({ isOpen, close, size = 'medium', children }) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[75]" onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={clsx('fixed inset-0 bg-gray-700 bg-opacity-75 ', {
              'backdrop-blur-sm': size !== 'mobile',
            })}
          />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div
            className={clsx(
              'min-h-full items-center justify-center text-center overflow-hidden',
              {
                block: size === 'mobile',
                flex: size !== 'mobile',
              },
            )}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                data-testid="dialog-panel"
                className={clsx(
                  'flex h-full max-h-[80vh] transform flex-col justify-start overflow-auto scrollbar-hide rounded-2xl border border-grey2-10 bg-white p-8 text-left align-middle shadow-md  transition-all',
                  {
                    'w-full rounded-none block mt-[154px]': size === 'mobile',
                    'max-w-md': size === 'small',
                    'w-[560px]': size === 'medium',
                    'max-w-3xl': size === 'large',
                  },
                )}
              >
                <ModalProvider close={close}>{children}</ModalProvider>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

const Title: React.FC<LiteProps> = ({ children }) => {
  const { close } = useModal();

  return (
    <Dialog.Title className="flex items-center justify-between">
      <div className="text-large-semi">{children}</div>
      <div>
        <button onClick={close}>
          <Cancel className="h-5 w-5" />
        </button>
      </div>
    </Dialog.Title>
  );
};

const Description: React.FC<LiteProps> = ({ children }) => {
  return (
    <Dialog.Description className="text-small-regular flex h-full items-center justify-center pt-2 pb-4 text-gray-700">
      {children}
    </Dialog.Description>
  );
};

const Body: React.FC<LiteProps> = ({ children }) => {
  return <div className="flex-1">{children}</div>;
};

const Footer: React.FC<LiteProps> = ({ children, size = 'medium' }) => {
  return (
    <div
      className={clsx('flex items-center  gap-x-4', {
        'justify-end': size !== 'mobile',
      })}
    >
      {children}
    </div>
  );
};

Modal.Title = Title;
Modal.Description = Description;
Modal.Body = Body;
Modal.Footer = Footer;

export default Modal;
