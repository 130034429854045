import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  createCart,
  updateCart,
  completeCart,
  addLineItem,
  updateLineItem,
  deleteLineItem,
  addShippingMethod,
  createPaymentSessions,
} from '../data/cart-data';

export const useCart = () => {
  const queryClient = useQueryClient();

  const createNewCart = useMutation(createCart);
  const updateCurrentCart = useMutation(updateCart, { retry: 3 });
  const validateCart = useMutation(completeCart, {
    onSuccess: () => {
      queryClient.invalidateQueries(['orders']);
    },
  });
  const addItem = useMutation(addLineItem);
  const updateItem = useMutation(updateLineItem);
  const removeItem = useMutation(deleteLineItem);
  const addShippingMethodToCart = useMutation(addShippingMethod);
  const createNewPaymentSessions = useMutation(createPaymentSessions);

  return {
    createNewCart,
    updateCurrentCart,
    validateCart,
    addItem,
    updateItem,
    removeItem,
    addShippingMethodToCart,
    createNewPaymentSessions,
  } as const;
};
