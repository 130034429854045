import { z } from 'zod';

const BaseApiSchema = z.object({
  id: z.string().optional(),
  created_at: z.nullable(z.string()).optional(),
  updated_at: z.nullable(z.string()).optional(),
  deleted_at: z.nullable(z.string()).optional(),
});

const ConditionApiSchema = BaseApiSchema.extend({
  type: z.nullable(
    z.union([
      z.literal('products'),
      z.literal('product_types'),
      z.literal('product_tags'),
      z.literal('customer_groups'),
      z.literal('product_variants'),
      z.literal('product_categories'),
    ]),
  ),
  operator: z.nullable(z.union([z.literal('in'), z.literal('not_in')])),
  product_tags: z
    .nullable(z.array(z.object({ value: z.nullable(z.string()) })))
    .optional(),
  customer_groups: z
    .nullable(z.array(z.object({ name: z.nullable(z.string()) })))
    .optional(),
});

const RuleApiSchema = BaseApiSchema.extend({
  allocation: z
    .nullable(z.union([z.literal('total'), z.literal('item')]))
    .optional(),
  description: z.nullable(z.string()).optional(),
  type: z.nullable(
    z.union([
      z.literal('fixed'),
      z.literal('percentage'),
      z.literal('free_shipping'),
      z.literal('complement'),
    ]),
  ),
  value: z.nullable(z.number()).optional(),
  conditions: z.nullable(z.array(ConditionApiSchema)).optional(),
});

export const DiscountApiSchema = BaseApiSchema.extend({
  code: z.nullable(z.string()).optional(),
  rule_id: z.nullable(z.string()).optional(),
  rule: z.nullable(RuleApiSchema).optional(),
  metadata: z.nullable(z.record(z.unknown())).optional(),
  start_at: z.string().optional(),
  ends_at: z.nullable(z.string()).optional(),
});

export type DiscountFromApi = z.infer<typeof DiscountApiSchema>;
export type RuleFromApi = z.infer<typeof RuleApiSchema>;
