import { z } from 'zod';
import { countries } from 'countries-list';
import { DropdownselectOption } from '../../types/global';
import { CreateCompanyPayload } from '../models/api/company-from-api';
import { useCatalogTranslation } from './use-catalog-translations';

export const useRegistrationForm = () => {
  const { ct } = useCatalogTranslation('common');
  const phoneRegex = /^\+?\d{1,3}\s?0?\d{9,10}$/;

  const registrationFormSchema = z.object({
    companyName: z
      .string()
      .min(1, { message: ct('Common.fieldRequired', 'Ce champ est requis') }),
    contactFirstName: z
      .string()
      .min(1, { message: ct('Common.fieldRequired', 'Ce champ est requis') })
      .max(38, {
        message: ct(
          'Common.fieldTooLong',
          'Ce champ est limité à {{max}} caractères',
          { max: 38 },
        ),
      }),
    contactLastName: z
      .string()
      .min(1, { message: ct('Common.fieldRequired', 'Ce champ est requis') })
      .max(38, {
        message: ct(
          'Common.fieldTooLong',
          'Ce champ est limité à {{max}} caractères',
          { max: 38 },
        ),
      }),
    contactPhone: z
      .string()
      .refine((value) => value && phoneRegex.test(value), {
        message: ct(
          'Common.invalidPhoneNumber',
          'Numéro de téléphone invalide',
        ),
      }),
    registrationNumber: z.string().optional(),
    vatNumber: z.string().optional(),
    address_1: z
      .string()
      .min(1, { message: ct('Common.fieldRequired', 'Ce champ est requis') })
      .max(38, {
        message: ct(
          'Common.fieldTooLong',
          'Ce champ est limité à {{max}} caractères',
          { max: 38 },
        ),
      }),
    address_2: z
      .string()
      .max(38, {
        message: ct(
          'Common.fieldTooLong',
          'Ce champ est limité à {{max}} caractères',
          { max: 38 },
        ),
      })
      .optional(),
    postalCode: z
      .string()
      .min(1, { message: ct('Common.fieldRequired', 'Ce champ est requis') })
      .max(12, {
        message: ct(
          'Common.fieldTooLong',
          'Ce champ est limité à {{max}} caractères',
          { max: 12 },
        ),
      }),
    city: z
      .string()
      .min(1, { message: ct('Common.fieldRequired', 'Ce champ est requis') }),
    country: z
      .object({ id: z.string(), value: z.string() })
      .optional()
      .refine((value) => value !== null && value !== undefined, {
        message: ct('Common.fieldRequired', 'Ce champ est requis'),
      }),
    termsAccepted: z.boolean().refine((value) => value === true, {
      message: ct(
        'Common.termsMustBeAccepted',
        'Vous devez accepter les termes et conditions',
      ),
    }),
  });

  const countryOptions: DropdownselectOption[] = Object.entries(countries)
    .map(([key, value]) => ({
      id: key,
      value: value.name,
    }))
    .sort((a, b) => a?.value.localeCompare(b?.value));

  const buildRegistrationPayload = (
    data: z.infer<typeof registrationFormSchema>,
    email: string,
  ): CreateCompanyPayload => {
    const address = {
      address_1: data.address_1,
      address_2: data.address_2,
      postal_code: data.postalCode,
      city: data.city,
      country_code: data.country.id.toLocaleLowerCase(),
    };
    return {
      email,
      first_name: data.contactFirstName,
      last_name: data.contactLastName,
      phone: data.contactPhone,
      company_name: data.companyName,
      registration_number: data.registrationNumber,
      vat_number: data.vatNumber,
      shipping_address: address,
      billing_address: address,
    };
  };

  return {
    registrationFormSchema,
    countryOptions,
    buildRegistrationPayload,
  } as const;
};
