import { useQuery } from '@tanstack/react-query';
import { useFavorites } from '../context/favorite-context';
import { getCustomerAccount } from '../data/customer-data';
import { FavoriteActions } from '../context/favorite-context/favorite-state';
import { useLogin } from '../context/login-context';

export const useMeAccount = () => {
  const { isLoggedIn } = useLogin();
  const queryKey = ['account'];
  const { dispatch } = useFavorites();
  const {
    data: customer,
    isLoading,
    refetch,
    remove,
  } = useQuery(queryKey, getCustomerAccount, {
    enabled: !!isLoggedIn,
    onSuccess(data) {
      const favoriteLists = data.favoriteLists.reduce((acc, current) => {
        return {
          ...acc,
          [current.id]: current.items.map((item) => item.variantId),
        };
      }, {});
      const favoriteProducts = new Set<string>();
      for (const list in favoriteLists) {
        favoriteLists[list].forEach((item: string) =>
          favoriteProducts.add(item),
        );
      }
      dispatch({
        type: FavoriteActions.SET_ALL_LISTS_AND_PRODUCT,
        payload: { favoriteLists, favoriteProducts },
      });
    },
    onError(data) {
      console.log('error getting customer', data);
    },
  });
  return { customer, isLoading, refetch, remove } as const;
};
