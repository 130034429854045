import CatalogButton from '../catalog-button';
import clsx from 'clsx';
import Modal from '../modal';
import { useEffect, useMemo, useState } from 'react';

import React from 'react';
import { useCatalogTranslation } from '@lib/hooks/use-catalog-translations';
import { ICompanyLight } from '@lib/models/view-models/company';
import { ICatalogCustomer } from '@lib/models/view-models/customer';

type DisconnectedModalProps = {
  company: ICompanyLight;
  customer: ICatalogCustomer;
  isOpen: boolean;
};

const DisconnectedModal = ({
  company,
  customer,
  isOpen,
}: DisconnectedModalProps): JSX.Element => {
  const [size, setSize] = useState<'medium' | 'mobile'>('medium');
  const { ct } = useCatalogTranslation('common');
  useEffect(() => {
    if (size === 'medium' && window.matchMedia('(max-width: 767px)').matches) {
      setSize('mobile');
      return;
    }
    if (size === 'mobile' && window.matchMedia('(min-width: 768px)').matches) {
      setSize('medium');
      return;
    }
  });

  const close = () => {
    window.location.reload();
  };

  const firstName =
    customer?.firstName ||
    customer?.email.split('@')[0].charAt(0).toUpperCase() +
      customer?.email.split('@')[0].slice(1);
  const customerName = useMemo(
    () => `${company?.name}, ${firstName}`,
    [company, customer],
  );

  return (
    <Modal isOpen={isOpen} close={close} size={size}>
      <Modal.Body>
        <div className="flex flex-col gap-2">
          <h2 className="font-header text-lg">
            {ct(
              'CommonError.customerDisconnected',
              "Attention vous n'êtes plus connecté en tant que {{customerName}}",
              {
                customerName,
              },
            )}
          </h2>
          <p className="text-base text-grey2-60">
            {ct(
              'CommonError.disconnectedThenRefresh',
              'La session de {{customerName}} a expiré. Nous allons rafraîchir le contenu la page',
              {
                customerName,
              },
            )}
          </p>
        </div>
      </Modal.Body>

      <Modal.Footer size={size}>
        <div
          className={clsx('flex gap-3 mt-6', {
            'justify-end gap-2': size !== 'mobile',
            'w-full': size === 'mobile',
          })}
        >
          <CatalogButton color="seller" type="submit" onClick={close}>
            {ct('CommonCta.agree', "J'ai compris")}
          </CatalogButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default DisconnectedModal;
