import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { getCustomerSettings } from '../data/customer-data';
import {
  getAccessibleColor,
  getRGBColor,
} from '../utils/helpers/color-converter';
import { Length, Mass, Volume } from 'convert';
import { useCatalogTranslation } from './use-catalog-translations';
import { useLogin } from '../context/login-context';

type SettingsFromApi = {
  id: string;
  created_at: string;
  updated_at: string;
  name: string;
  value: string;
  description: string;
};

export type StoreSettings = {
  logo?: string;
  color?: string;
  homeBanner?: string;
  sellerName?: string;
  sellerNameTrimLow?: string;
  lengthUnit: Length;
  massUnit: Mass;
  volumeUnit: Volume;
  stockLevelEnabled: boolean;
  outOfStockEnabled: boolean;
  quantityLeftEnabled: boolean;
  limitedStockEnabled: boolean;
  limitedStockThreshold: number | null;
  disableAddToCartWhenNoStock: boolean;
  disableAddAddress: boolean;
  legalNotices?: string;
  termsAndConditions?: string;
  privacyPolicy?: string;
  allowOverridePcb?: boolean;
  preorderDepositPercentage?: number;
  disableDeliveryDate?: boolean;
  useCompressedImages: boolean;
  productTableEnabled: boolean;
  defaultProductsLayout: 'list' | 'grid';
  minDeliveryDelay: number;
  christmasBanner: boolean;
  christmasBannerColor: string;
  enableBuyerNewCustomerForm: boolean;
  allowSmartOrderUpload: boolean;
  restockNotificationEnabled: boolean;
  quoteAllowed: boolean;
  reservationAllowed: boolean;
  quoteToOrderAllowed: boolean;
  quoteToReservationAllowed: boolean;
  reservationToOrderAllowed: boolean;
  displayPaymentStatus: boolean;
  displayCategories: boolean;
  hideTaxesAndShippingCosts: boolean;
  promoBanner: boolean;
  promoLink: string;
  promoBgColor: string;
  promoBannerUrl: string;
  displayCategoryThumbnails: boolean;
  intercomActive: boolean;
};

type FooterLinks = {
  label: string;
  link: string;
};

export const buildSettings = (data: unknown): StoreSettings => {
  let logo: string;
  let color: string;
  let homeBanner: string;
  let legalNotices: string;
  let termsAndConditions: string;
  let privacyPolicy: string;
  let sellerName: string;
  let sellerNameTrimLow: string;
  let lengthUnit: Length = 'mm';
  let massUnit: Mass = 'g';
  let volumeUnit: Volume = 'mL';
  let disableAddAddress = false;
  let stockLevelEnabled = false;
  let outOfStockEnabled = false;
  let disableAddToCartWhenNoStock = false;
  let quantityLeftEnabled = false;
  let limitedStockEnabled = false;
  let limitedStockThreshold: number | null = null;
  let allowOverridePcb = false;
  let preorderDepositPercentage = 0;
  let disableDeliveryDate = false;
  let useCompressedImages = false;
  let productTableEnabled = false;
  let defaultProductsLayout: 'list' | 'grid' = 'grid';
  let minDeliveryDelay = 7;
  let christmasBanner;
  let christmasBannerColor: string;
  let enableBuyerNewCustomerForm = false;
  let allowSmartOrderUpload = false;
  let restockNotificationEnabled = false;
  let quoteAllowed = false;
  let reservationAllowed = false;
  let quoteToOrderAllowed: boolean;
  let quoteToReservationAllowed: boolean;
  let reservationToOrderAllowed: boolean;
  let displayPaymentStatus = false;
  let displayCategories = false;
  let hideTaxesAndShippingCosts = false;
  let promoBanner = false;
  let promoLink;
  let promoBgColor;
  let promoBannerUrl;
  let displayCategoryThumbnails = false;
  let intercomActive = false;

  if (typeof data === 'object' && Object.hasOwn(data, 'settings')) {
    const { settings } = data as { settings: SettingsFromApi[] };
    for (const setting of settings) {
      switch (setting.name) {
        case 'seller_logo':
          logo = setting.value;
          break;
        case 'buyer_homepage_top_banner':
          homeBanner = setting.value;
          break;
        case 'seller_color':
          color = setting.value;
          break;
        case 'seller_name':
          sellerName = setting.value;
          break;
        case 'seller_name_trim_low':
          sellerNameTrimLow = setting.value;
          break;
        case 'display_length_unit':
          lengthUnit = setting.value as Length;
          break;
        case 'display_mass_unit':
          massUnit = setting.value as Mass;
          break;
        case 'display_volume_unit':
          volumeUnit = setting.value as Volume;
          break;
        case 'disable_add_address':
          disableAddAddress = setting.value.toLowerCase() === 'true';
          break;
        case 'seller_legal_notices':
          legalNotices = setting.value;
          break;
        case 'seller_terms_and_conditions':
          termsAndConditions = setting.value;
          break;
        case 'seller_privacy':
          privacyPolicy = setting.value;
          break;
        case 'stock_level_enabled':
          stockLevelEnabled = JSON.parse(setting.value.toLowerCase());
          break;
        case 'out_of_stock_enabled':
          outOfStockEnabled = JSON.parse(setting.value.toLowerCase());
          break;
        case 'quantity_left_enabled':
          quantityLeftEnabled = JSON.parse(setting.value.toLowerCase());
          break;
        case 'limited_stock_enabled':
          limitedStockEnabled = JSON.parse(setting.value.toLowerCase());
          break;
        case 'limited_stock_threshold':
          limitedStockThreshold =
            setting.value === 'null' ? null : parseInt(setting.value);
          break;
        case 'allow_override_pcb':
          allowOverridePcb = setting.value.toLowerCase() === 'true';
          break;
        case 'preorder_deposit_percentage':
          preorderDepositPercentage = parseInt(setting.value);
          break;
        case 'disable_add_to_cart_when_no_stock':
          disableAddToCartWhenNoStock = JSON.parse(setting.value.toLowerCase());
          break;
        case 'disable_delivery_date':
          disableDeliveryDate = JSON.parse(setting.value.toLowerCase());
          break;
        case 'use_compressed_images':
          useCompressedImages = setting.value.toLowerCase() === 'true';
          break;
        case 'product_table_enabled':
          productTableEnabled = setting.value.toLowerCase() === 'true';
          break;
        case 'default_products_layout':
          defaultProductsLayout =
            setting.value.toLowerCase() === 'list' ? 'list' : 'grid';
          break;
        case 'min_delivery_delay':
          minDeliveryDelay = parseInt(setting.value);
          break;
        case 'christmas_banner':
          christmasBanner = JSON.parse(setting.value.toLowerCase());
          break;
        case 'christmas_banner_color':
          christmasBannerColor = setting.value;
          break;
        case 'enable_buyer_new_customer_form':
          enableBuyerNewCustomerForm = JSON.parse(setting.value.toLowerCase());
          break;
        case 'allow_smart_order_upload':
          allowSmartOrderUpload = JSON.parse(setting.value.toLowerCase());
          break;
        case 'restock_notification_enabled':
          restockNotificationEnabled = JSON.parse(setting.value.toLowerCase());
          break;
        case 'reservation_allowed':
          reservationAllowed = JSON.parse(setting.value.toLowerCase());
          break;
        case 'quote_allowed':
          quoteAllowed = JSON.parse(setting.value.toLowerCase());
          break;
        case 'quote_to_order_allowed':
          quoteToOrderAllowed = JSON.parse(setting.value.toLowerCase());
          break;
        case 'quote_to_reservation_allowed':
          quoteToReservationAllowed = JSON.parse(setting.value.toLowerCase());
          break;
        case 'reservation_to_order_allowed':
          reservationToOrderAllowed = JSON.parse(setting.value.toLowerCase());
          break;
        case 'display_payment_status':
          displayPaymentStatus = JSON.parse(setting.value.toLowerCase());
          break;
        case 'display_categories':
          displayCategories = JSON.parse(setting.value.toLowerCase());
          break;
        case 'hide_taxes_and_shipping_costs':
          hideTaxesAndShippingCosts = JSON.parse(setting.value.toLowerCase());
          break;
        case 'promo_banner':
          promoBanner = JSON.parse(setting.value.toLowerCase());
          break;
        case 'promo_link':
          promoLink = setting.value;
          break;
        case 'promo_bg_color':
          promoBgColor = setting.value;
          break;
        case 'promo_banner_url':
          promoBannerUrl = setting.value;
          break;
        case 'display_category_thumbnails':
          displayCategoryThumbnails = JSON.parse(setting.value.toLowerCase());
          break;
        case 'intercom_active':
          intercomActive = JSON.parse(setting.value.toLowerCase());
          break;
      }
    }
    return {
      logo,
      homeBanner,
      color,
      sellerName,
      sellerNameTrimLow,
      lengthUnit,
      massUnit,
      volumeUnit,
      disableAddAddress,
      legalNotices,
      termsAndConditions,
      privacyPolicy,
      stockLevelEnabled,
      outOfStockEnabled,
      quantityLeftEnabled,
      limitedStockEnabled,
      limitedStockThreshold,
      allowOverridePcb,
      preorderDepositPercentage,
      disableAddToCartWhenNoStock,
      disableDeliveryDate,
      useCompressedImages,
      productTableEnabled,
      defaultProductsLayout,
      minDeliveryDelay,
      christmasBanner,
      christmasBannerColor,
      enableBuyerNewCustomerForm,
      allowSmartOrderUpload,
      restockNotificationEnabled,
      quoteAllowed,
      reservationAllowed,
      quoteToOrderAllowed,
      quoteToReservationAllowed,
      reservationToOrderAllowed,
      displayPaymentStatus,
      displayCategories,
      hideTaxesAndShippingCosts,
      promoBanner,
      promoLink,
      promoBgColor,
      promoBannerUrl,
      displayCategoryThumbnails,
      intercomActive,
    } as StoreSettings;
  }
  throw new Error('Invalid settings data');
};

export const useSettings = () => {
  const { isLoggedIn } = useLogin();

  const queryKey = ['settings'];
  const { data: settings, isLoading: retreivingSettings } = useQuery(
    queryKey,
    getCustomerSettings,
    {
      select: buildSettings,
      enabled: !!isLoggedIn,
    },
  );

  return { ...settings, retreivingSettings } as const;
};

export const useColorsSettings = () => {
  const { color } = useSettings();
  const [sellerColor, setSellerColor] = useState(
    getRGBColor(color || '#000000', 'seller'),
  );
  const [sellerFontColor, setSellerFontColor] = useState(
    getRGBColor(color || '#FFFFFF', 'sellerFont'),
  );

  useEffect(() => {
    setSellerColor(getRGBColor(color || '#000000', 'seller'));
    setSellerFontColor(
      getRGBColor(getAccessibleColor(color || '#FFFFFF'), 'sellerFont'),
    );
  }, [color]);

  return { color, sellerColor, sellerFontColor } as const;
};

export const useFooterLinks = () => {
  const { ct } = useCatalogTranslation('common');
  const { legalNotices, termsAndConditions, privacyPolicy } = useSettings();
  const footerLinks = [];
  if (legalNotices) {
    footerLinks.push({
      label: ct('Common.legalNotices', 'Mentions Légales'),
      link: legalNotices,
    });
  }
  if (termsAndConditions) {
    footerLinks.push({
      label: ct('Common.termsAndConditions', 'CGV'),
      link: termsAndConditions,
    });
  }
  if (privacyPolicy) {
    footerLinks.push({
      label: ct('Common.privacyPolicy', 'Confidentialité'),
      link: privacyPolicy,
    });
  }
  return footerLinks as FooterLinks[];
};

export const useChristmasBannerSettings = () => {
  const { christmasBanner, christmasBannerColor } = useSettings();
  return { christmasBanner, christmasBannerColor } as const;
};

export const useQuoteSettings = () => {
  const {
    quoteAllowed,
    reservationAllowed,
    quoteToOrderAllowed,
    quoteToReservationAllowed,
    reservationToOrderAllowed,
  } = useSettings();
  return {
    quoteAllowed,
    reservationAllowed,
    quoteToOrderAllowed,
    quoteToReservationAllowed,
    reservationToOrderAllowed,
    orderTypeBlockActive: quoteAllowed || reservationAllowed,
  } as const;
};
