import { z } from 'zod';
import { AddressApiSchema } from './address-from-api';
import { LineItemApiSchema } from './line-item-from-api';
import { DiscountApiSchema } from './discount-from-api';

const BaseApiSchema = z.object({
  id: z.string().optional(),
  created_at: z.nullable(z.string()).optional(),
  updated_at: z.nullable(z.string()).optional(),
  deleted_at: z.nullable(z.string()).optional(),
});

export const PaymentApiSchema = BaseApiSchema.extend({
  amount: z.nullable(z.number()).optional(),
  amount_refunded: z.nullable(z.number()).optional(),
  captured_at: z.nullable(z.string()).optional(),
  cart_id: z.nullable(z.string()).optional(),
  currency_code: z.nullable(z.string()).optional(),
  idempotency_key: z.nullable(z.string()).optional(),
  metadata: z.nullable(z.record(z.unknown())).optional(),
  order_id: z.nullable(z.string()).optional(),
  provider_id: z.nullable(z.string()).optional(),
  swap_id: z.nullable(z.string()).optional(),
  data: z.nullable(
    z.object({
      status: z.nullable(z.string()).optional(),
      payment_method: z.nullable(z.string()).optional(),
      payment_method_data: z
        .nullable(
          z.object({
            delay: z.nullable(z.number().or(z.string())).optional(),
          }),
        )
        .optional(),
    }),
  ),
});

export const PaymentSessionApiSchema = z.object({
  cart_id: z.nullable(z.string()).optional(),
  provider_id: z.nullable(z.string()).optional(),
  is_selected: z.nullable(z.boolean()).optional(),
  is_initiated: z.nullable(z.boolean()).optional(),
  status: z.nullable(z.string()).optional(),
  idempotency_key: z.nullable(z.string()).optional(),
  amount: z.nullable(z.number()).optional(),
  payment_authorized_at: z.nullable(z.string()).optional(),
  data: z
    .nullable(
      z.object({
        payment_method: z.nullable(z.string()).optional(),
        payment_method_data: z
          .nullable(
            z.object({
              delay: z.nullable(z.number().or(z.string())).optional(),
            }),
          )
          .optional(),
        client_secret: z.nullable(z.string()).optional(),
      }),
    )
    .optional(),
});

export const CountryApiSchema = z.object({
  id: z.nullable(z.number()).optional(),
  display_name: z.nullable(z.string()).optional(),
  iso_2: z.nullable(z.string()).optional(),
  iso_3: z.nullable(z.string()).optional(),
  num_code: z.nullable(z.number()).optional(),
  region_id: z.nullable(z.string()).optional(),
  name: z.nullable(z.string()).optional(),
});

export const RegionApiSchema = BaseApiSchema.extend({
  automatic_taxes: z.nullable(z.boolean()).optional(),
  currency_code: z.nullable(z.string()).optional(),
  gift_card_taxable: z.nullable(z.boolean()).optional(),
  name: z.nullable(z.string()).optional(),
  tax_code: z.nullable(z.string()).optional(),
  tax_provider_id: z.nullable(z.string()).optional(),
  tax_rate: z.nullable(z.number()).optional(),
  countries: z.nullable(z.array(CountryApiSchema)).optional(),
  fulfillment_providers: z
    .nullable(
      z.array(
        z.object({
          id: z.nullable(z.string()).optional(),
          is_installed: z.nullable(z.boolean()).optional(),
        }),
      ),
    )
    .optional(),
  payment_providers: z
    .nullable(
      z.array(
        z.object({
          id: z.nullable(z.string()).optional(),
          is_installed: z.nullable(z.boolean()).optional(),
        }),
      ),
    )
    .optional(),
});

export const SalesChannelSchema = BaseApiSchema.extend({
  name: z.nullable(z.string()).optional(),
  description: z.nullable(z.string()).optional(),
  is_disabled: z.nullable(z.boolean()).optional(),
});

export const ShippingOptionApiSchema = BaseApiSchema.extend({
  admin_only: z.nullable(z.boolean()).optional(),
  amount: z.nullable(z.number()).optional(),
  data: z.nullable(z.record(z.unknown())).optional(),
  is_return: z.nullable(z.boolean()).optional(),
  metadata: z.nullable(z.record(z.unknown())).optional(),
  name: z.nullable(z.string()).optional(),
  price_type: z.nullable(z.string()).optional(),
  profile_id: z.nullable(z.string()).optional(),
  region_id: z.nullable(z.string()).optional(),
});

export const ShippingMethodsSchema = z.object({
  id: z.nullable(z.string()).optional(),
  cart_id: z.nullable(z.string()).optional(),
  claim_order_id: z.nullable(z.string()).optional(),
  data: z.nullable(z.record(z.unknown())).optional(),
  order_id: z.nullable(z.string()).optional(),
  original_tax_total: z.nullable(z.number()).optional(),
  original_total: z.nullable(z.number()).optional(),
  price: z.nullable(z.number()).optional(),
  return_id: z.nullable(z.string()).optional(),
  subtotal: z.nullable(z.number()).optional(),
  tax_total: z.nullable(z.number()).optional(),
  total: z.nullable(z.number()).optional(),
  shipping_option_id: z.nullable(z.string()).optional(),
  shipping_option: z.nullable(ShippingOptionApiSchema).optional(),
  tax_lines: z
    .nullable(
      z.array(
        z.object({
          rate: z.nullable(z.number()).optional(),
          code: z.nullable(z.string()).optional(),
          item_id: z.nullable(z.string()).optional(),
          name: z.nullable(z.string()).optional(),
        }),
      ),
    )
    .optional(),
});

export const CartApiSchema = BaseApiSchema.extend({
  billing_address_id: z.nullable(z.string()).optional(),
  billing_address: z.nullable(AddressApiSchema).optional(),
  delivery_date: z.nullable(z.string()).optional(),
  completed_at: z.nullable(z.string()).optional(),
  context: z
    .nullable(
      z.object({
        ip: z.nullable(z.string()).optional(),
        user_agent: z.nullable(z.string()).optional(),
      }),
    )
    .optional(),
  discount_total: z.nullable(z.number()).optional(),
  email: z.nullable(z.string().email()).optional(),
  idempotency_key: z.nullable(z.string()).optional(),
  metadata: z.nullable(z.record(z.unknown())).optional(),
  object: z.nullable(z.string()).optional(),
  items: z.nullable(z.array(LineItemApiSchema)).optional(),
  payment: z.nullable(PaymentApiSchema).optional(),
  payment_authorized_at: z.nullable(z.string().datetime()).optional(),
  payment_id: z.nullable(z.string()).optional(),
  payment_session_id: z.nullable(z.string()).optional(),
  payment_sessions: z.nullable(z.array(PaymentSessionApiSchema)).optional(),
  payment_session: z.nullable(PaymentSessionApiSchema).optional(),
  region: z.nullable(RegionApiSchema).optional(),
  region_id: z.nullable(z.string()).optional(),
  sales_channel: z.nullable(SalesChannelSchema).optional(),
  sales_channel_id: z.nullable(z.string()).optional(),
  shipping_address_id: z.nullable(z.string()).optional(),
  shipping_address: z.nullable(AddressApiSchema).optional(),
  shipping_methods: z.nullable(z.array(ShippingMethodsSchema)).optional(),
  shipping_total: z.nullable(z.number()).optional(),
  gift_card_total: z.nullable(z.number()).optional(),
  shipping_tax_total: z.nullable(z.number()).optional(),
  subtotal: z.nullable(z.number()).optional(),
  item_tax_total: z.nullable(z.number()).optional(),
  tax_total: z.nullable(z.number()).optional(),
  total: z.nullable(z.number()).optional(),
  type: z.nullable(z.string()).optional(),
  external_id: z.nullable(z.string()).optional(),
  discounts: z.nullable(z.array(DiscountApiSchema)).optional(),
});

export const UpdateCartReqSchema = z.object({
  region_id: z.string().optional(),
  country_code: z.string().optional(),
  email: z.string().email().optional(),
  delivery_date: z.date().optional(),
  sales_channel_id: z.string().optional(),
  billing_address: z.union([AddressApiSchema, z.string()]).optional(),
  shipping_address: z.union([AddressApiSchema, z.string()]).optional(),
  gift_cards: z.array(z.object({ code: z.string() })).optional(),
  dicounts: z.array(z.object({ code: z.string() })).optional(),
  customer_id: z.string().optional(),
  context: z.object({ ip: z.string(), user_agent: z.string() }).optional(),
  metadata: z.record(z.unknown()).optional(),
  external_id: z.string().optional(),
});

export type RegionFromApi = z.infer<typeof RegionApiSchema>;
export type CountryFromApi = z.infer<typeof CountryApiSchema>;
export type SalesChannelFromApi = z.infer<typeof SalesChannelSchema>;
export type ShippingMethodFromApi = z.infer<typeof ShippingMethodsSchema>;
export type ShippingOptionFromApi = z.infer<typeof ShippingOptionApiSchema>;
export type PaymentFromApi = z.infer<typeof PaymentApiSchema>;
export type PaymentSessionFromApi = z.infer<typeof PaymentSessionApiSchema>;
export type CartFromApi = z.infer<typeof CartApiSchema>;
export type UpdateCartReq = z.infer<typeof UpdateCartReqSchema>;
