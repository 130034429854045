import { catalogRequest } from '../config';

type AddressPayload = {
  address: {
    first_name: string;
    last_name?: string;
    address_1: string;
    address_2?: string;
    city: string;
    postal_code: string;
    country_code: string;
  };
};

export const addCustomerCompanyShippingAddress = async ({
  payload,
}: {
  payload: AddressPayload;
}) => {
  const path = 'store/customers/me/company/addresses';
  return await catalogRequest('post', path, payload).then((res) => res.data);
};
