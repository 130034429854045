import useToggleState from '@lib/hooks/use-toggle-state';
import { useRouter } from 'next/router';
import { createContext, useContext, useEffect, useState } from 'react';

interface CartDropdownContext {
  state: boolean;
  open: () => void;
  timedOpen: (variantId: string) => void;
  close: () => void;
  lastItemId?: string;
}

export const CartDropdownContext = createContext<CartDropdownContext | null>(
  null,
);

export const CartDropdownProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { state, close, open } = useToggleState();
  const [activeTimer, setActiveTimer] = useState<NodeJS.Timer | undefined>(
    undefined,
  );
  const [lastItemId, setLastItemId] = useState<string>();
  const route = useRouter();

  const timedOpen = (variantId: string) => {
    if (route.pathname.includes('/cart')) return;

    open();

    const timer = setTimeout(close, 5000);

    setActiveTimer(timer);
    setLastItemId(variantId);
  };

  const openAndCancel = () => {
    if (route.pathname.includes('/cart')) return;
    if (activeTimer) {
      clearTimeout(activeTimer);
    }

    open();
  };

  // Clean up the timer when the component unmounts
  useEffect(() => {
    return () => {
      if (activeTimer) {
        clearTimeout(activeTimer);
      }
    };
  }, [activeTimer]);

  return (
    <CartDropdownContext.Provider
      value={{ state, close, open: openAndCancel, timedOpen, lastItemId }}
    >
      {children}
    </CartDropdownContext.Provider>
  );
};

export const useCartDropdown = () => {
  const context = useContext(CartDropdownContext);

  if (context === null) {
    throw new Error(
      'useCartDropdown must be used within a CartDropdownProvider',
    );
  }

  return context;
};
