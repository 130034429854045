import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import {
  addNewCustomerAndCompany,
  getCustomerCompany,
  resetCustomerPassword,
} from '../data/customer-data';
import { addCustomerCompanyShippingAddress } from '../data/shipping-address';
import { buildCompany } from '../utils/company';
import { useLogin } from '../context/login-context';

export const useCustomerCompany = () => {
  const router = useRouter();
  const queryKey = ['customerCompany'];
  const { isLoggedIn } = useLogin();

  const { data: company } = useQuery(queryKey, getCustomerCompany, {
    enabled: !!isLoggedIn,
    select: buildCompany,
    onSuccess: (data) => {
      if (!data && !data.regionId) {
        router.push('/configuration-error');
      }
    },
  });

  return {
    company,
  } as const;
};

export const useCustomerCompanyMutations = () => {
  const queryClient = useQueryClient();
  const queryKey = ['customerCompany'];

  const addShippingAddress = useMutation(addCustomerCompanyShippingAddress, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
    },
  });

  const createNewCustomerMutation = useMutation(addNewCustomerAndCompany, {
    onSuccess: () => {
      queryClient.invalidateQueries(['account']);
    },
  });

  const updateCustmerPasswordMutation = useMutation(resetCustomerPassword, {
    onSuccess: () => {
      queryClient.invalidateQueries(['account']);
    },
  });

  return {
    addShippingAddress,
    createNewCustomerMutation,
    updateCustmerPasswordMutation,
  } as const;
};
