import { LineItemFromApi } from '@lib/models/api/line-item-from-api';
import {
  PaymentFromApi,
  SalesChannelFromApi,
  ShippingOptionFromApi,
  ShippingMethodFromApi,
  CartFromApi,
  PaymentSessionFromApi,
} from '../../models/api/cart-from-api';
import {
  ICartItem,
  ICatalogCart,
  IPayment,
  IPaymentSession,
  ISalesChannel,
  IShippingMethod,
  IShippingOption,
} from '../../models/view-models/cart';
import { buildAddress, buildRegion } from '../address';
import { buildStoreVariant } from '../products';
import { StoreSettings } from '@lib/hooks/use-settings';
import { buildDiscount } from '../discount';
import { DiscountFromApi } from '@lib/models/api/discount-from-api';
import _ from 'lodash';
import convert, { Length } from 'convert';

export const buildPayment = (payment: PaymentFromApi): IPayment => {
  return {
    id: payment.id,
    createdAt: new Date(payment.created_at),
    updatedAt: payment.updated_at ? new Date(payment.updated_at) : undefined,
    deletedAt: payment.deleted_at ? new Date(payment.deleted_at) : undefined,
    capturedAt: payment.captured_at ? new Date(payment.captured_at) : undefined,
    cartId: payment.cart_id,
    amount: payment.amount / 100,
    amountRefunded: payment.amount_refunded / 100,
    orderId: payment.order_id,
    providerId: payment.provider_id,
    swapId: payment.swap_id,
    currencyCode: payment.currency_code,
    metadata: payment.metadata,
    data: payment.data
      ? {
          status: payment.data.status,
          paymentMethod: payment.data.payment_method,
          paymentMethodData: {
            delay: Number(payment.data.payment_method_data.delay),
          },
        }
      : undefined,
  };
};

export const buildPaymentSession = (
  ps: PaymentSessionFromApi,
): IPaymentSession => {
  const getDelay = (delayFromApi: string | number): number => {
    if (!delayFromApi) {
      return null;
    }
    if (typeof delayFromApi === 'string') {
      return parseInt(delayFromApi);
    }
    return delayFromApi;
  };
  return {
    cartId: ps.cart_id,
    providerId: ps.provider_id,
    isSelected: ps.is_selected,
    isInitiated: ps.is_initiated,
    status: ps.status,
    data: {
      paymentMethod: ps.data?.payment_method || null,
      delay: getDelay(ps.data?.payment_method_data?.delay),
      clientSecret: ps.data?.client_secret,
    },
    amount: ps.amount / 100,
  };
};

const buildSalesChannel = (
  salesChannel: SalesChannelFromApi,
): ISalesChannel => {
  return {
    id: salesChannel.id,
    createdAt: new Date(salesChannel.created_at),
    updatedAt: salesChannel.updated_at
      ? new Date(salesChannel.updated_at)
      : undefined,
    deletedAt: salesChannel.deleted_at
      ? new Date(salesChannel.deleted_at)
      : undefined,
    name: salesChannel.name,
    description: salesChannel.description,
    isDisabled: salesChannel.is_disabled,
  };
};

const buildShippingOption = (
  shippingOption: ShippingOptionFromApi,
): IShippingOption => {
  return {
    id: shippingOption.id,
    createdAt: shippingOption.created_at
      ? new Date(shippingOption.created_at)
      : undefined,
    updatedAt: shippingOption.updated_at
      ? new Date(shippingOption.updated_at)
      : undefined,
    deletedAt: shippingOption.deleted_at
      ? new Date(shippingOption.deleted_at)
      : undefined,
    adminOnly: shippingOption.admin_only,
    amount: shippingOption.amount / 100,
    data: shippingOption.data,
    isReturn: shippingOption.is_return,
    metadata: shippingOption.metadata,
    name: shippingOption.name,
    priceType: shippingOption.price_type,
    profileId: shippingOption.profile_id,
    regionId: shippingOption.region_id,
  };
};

export const buildShippingMethod = (
  shippingMethod: ShippingMethodFromApi,
): IShippingMethod => {
  return {
    id: shippingMethod.id,
    cartId: shippingMethod.cart_id,
    claimOrderId: shippingMethod.claim_order_id,
    data: shippingMethod.data,
    orderId: shippingMethod.order_id,
    originalTaxTotal: shippingMethod.original_tax_total,
    originalTotal: shippingMethod.original_total,
    price: shippingMethod.price,
    returnId: shippingMethod.return_id,
    subtotal: shippingMethod.subtotal / 100,
    taxTotal: shippingMethod.tax_total / 100,
    total: shippingMethod.total / 100,
    shippingOptionId: shippingMethod.shipping_option_id,
    shippingOption: shippingMethod.shipping_option
      ? buildShippingOption(shippingMethod.shipping_option)
      : undefined,
    taxLines: shippingMethod.tax_lines.map((tl) => ({
      ...tl,
      itemId: tl.item_id,
    })),
  };
};

export const buildCartItem = (
  cartItem: LineItemFromApi,
  discounts: { [discount_id: string]: DiscountFromApi },
  displaySettings: StoreSettings,
): ICartItem => {
  const displayUnit = cartItem.variant.metadata.display_unit as Length;
  const storageUnit = cartItem.variant.metadata.storage_unit as Length;
  const decimals = !!displayUnit && !!storageUnit;
  return {
    id: cartItem.id,
    rank: cartItem.rank,
    createdAt: cartItem.created_at ? new Date(cartItem.created_at) : undefined,
    updatedAt: cartItem.updated_at ? new Date(cartItem.updated_at) : undefined,
    title: cartItem.variant?.title || cartItem.title,
    thumbnail:
      cartItem.variant?.thumbnail || cartItem.thumbnail || '/placeholder.png',
    allowDiscount: cartItem.allow_discount,
    description: cartItem.description,
    metadata: cartItem.metadata,
    hasShipping: cartItem.has_shipping,
    lineDiscount:
      cartItem.adjustments
        ?.filter((a) => discounts[a.discount_id]?.rule?.allocation === 'item')
        .reduce((acc, a) => acc + a.amount, 0) / 100,
    discountTotal: cartItem.discount_total / 100,
    fulfilledQuantity: cartItem.fulfilled_quantity,
    isGiftCard: cartItem.is_giftcard,
    isReturn: cartItem.is_return,
    orderEditId: cartItem.order_edit_id,
    originalItemId: cartItem.original_item_id,
    originalTaxtTotal: cartItem.original_tax_total,
    originalTotal: cartItem.original_total,
    quantity: decimals
      ? convert(cartItem.quantity, storageUnit).to(displayUnit)
      : cartItem.quantity,
    returnedQuantity: cartItem.returned_quantity,
    shippedQuantity: cartItem.shipped_quantity,
    subtotal: cartItem.subtotal / 100,
    taxTotal: cartItem.tax_total / 100,
    total: cartItem.total / 100,
    unitPrice: decimals
      ? convert(cartItem.unit_price / 100, displayUnit).to(storageUnit)
      : cartItem.unit_price / 100,
    variantId: cartItem.variant_id,
    variant: buildStoreVariant(cartItem.variant, displaySettings),
    displayUnit,
    storageUnit,
    taxLines:
      cartItem.tax_lines?.map((tl) => ({
        ...tl,
        itemId: tl.item_id,
      })) || [],
  };
};

export const buildCart = (
  cart: CartFromApi,
  displaySettings?: StoreSettings,
): ICatalogCart => {
  const discountDic = Object.fromEntries(
    (cart.discounts || []).map((d) => [d.id, d]),
  );
  const items =
    cart.items
      ?.map((i) =>
        buildCartItem(i, discountDic, displaySettings || ({} as StoreSettings)),
      )
      .sort((a, b) => a.rank - b.rank) || [];
  const itemsWithVariant = items.filter((i) => i.variant);
  const packagingPrice =
    (cart.shipping_methods?.[0]?.data?.included_packaging_price as number) || 0;
  const adjustments = cart.items?.flatMap((i) => i.adjustments || []) || [];
  const adjustmentsDic = _.groupBy(adjustments, (a) => a.discount_id);
  const totalWeight = cart.items.reduce((acc, curr) => {
    const weight = curr.quantity * (curr.variant.weight ?? 0);
    return acc + weight;
  }, 0);
  return {
    id: cart.id,
    createdAt: cart.created_at ? new Date(cart.created_at) : undefined,
    updatedAt: cart.updated_at ? new Date(cart.updated_at) : undefined,
    deletedAt: cart.deleted_at ? new Date(cart.deleted_at) : undefined,
    completedAt: cart.completed_at ? new Date(cart.completed_at) : undefined,
    lastUpdatedAt: getLastUpdatedAt(cart),
    deliveryDate: cart.delivery_date ? new Date(cart.delivery_date) : undefined,
    billingAddressId: cart.billing_address_id,
    billingAddress: buildAddress(cart.billing_address),
    context: cart.context
      ? { ip: cart.context.ip, userAgent: cart.context.user_agent }
      : null,
    email: cart.email,
    idempotencyKey: cart.idempotency_key,
    metadata: cart.metadata,
    object: cart.object,
    items,
    payment: cart.payment ? buildPayment(cart.payment) : undefined,
    paymentAuthrizedAt: cart.payment_authorized_at
      ? new Date(cart.payment_authorized_at)
      : undefined,
    paymentId: cart.payment_id,
    paymentSessions: cart.payment_sessions
      ? cart.payment_sessions.map(buildPaymentSession)
      : [],
    paymentSession: cart.payment_session
      ? buildPaymentSession(cart.payment_session)
      : undefined,
    region: cart.region ? buildRegion(cart.region) : undefined,
    regionId: cart.region_id,
    salesChannel: cart.sales_channel
      ? buildSalesChannel(cart.sales_channel)
      : null,
    salesChannelId: cart.sales_channel_id,
    shippingAddressId: cart.shipping_address_id,
    shippingAddress: buildAddress(cart.shipping_address),
    shippingMethods: cart.shipping_methods?.map(buildShippingMethod) || [],
    packagingTotal: packagingPrice / 100,
    shippingTotal: (cart.shipping_total - packagingPrice) / 100,
    giftCardTotal: cart.gift_card_total / 100,
    discountTotal: cart.discount_total / 100,
    shippingTotalTax: cart.shipping_tax_total / 100,
    subtotal: cart.subtotal / 100,
    itemTaxTotal: cart.item_tax_total / 100,
    taxTotal: cart.tax_total / 100,
    total: cart.total / 100,
    type: cart.type,
    externalId: cart.external_id,
    totalWeight: totalWeight ?? 0,
    isPreorder: itemsWithVariant[0]?.variant.isPreorder ?? null,
    discounts: (cart.discounts || []).map((d) =>
      buildDiscount(d, adjustmentsDic[d.id]),
    ),
  };
};

const getLastUpdatedAt = (cart: CartFromApi): Date => {
  const dates = [cart.updated_at, ...cart.items.map((i) => i.updated_at)]
    .filter(Boolean)
    .map((d) => new Date(d));
  if (!dates.length) return undefined;
  return new Date(Math.max(...dates.map((d) => d.getTime())));
};
