import { z } from 'zod';
import { FavoriteApiSchema } from './favorite-from-api';
import { DiscountApiSchema } from './discount-from-api';

const BaseApiSchema = z.object({
  id: z.string().optional(),
  created_at: z.nullable(z.string()).optional(),
  updated_at: z.nullable(z.string()).optional(),
  deleted_at: z.nullable(z.string()).optional(),
});

export const CustomerApiSchema = BaseApiSchema.extend({
  email: z.nullable(z.string().email()),
  first_name: z.nullable(z.string()).optional(),
  last_name: z.nullable(z.string()).optional(),
  phone: z.nullable(z.string()).optional(),
  has_account: z.nullable(z.boolean()).optional(),
  metadata: z.nullable(z.record(z.unknown())).optional(),
  favorite_lists: z.nullable(z.array(FavoriteApiSchema)).optional(),
  discounts: z.nullable(z.array(DiscountApiSchema)).optional(),
  original_user: z
    .object({
      id: z.string(),
      email: z.string(),
    })
    .optional(),
});

export type CustomerFromApi = z.infer<typeof CustomerApiSchema>;
