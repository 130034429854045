import { StoreSettings } from '../../hooks/use-settings';
import {
  FavoriteFromApi,
  FavoriteItemFromApi,
} from '../../models/api/favorite-from-api';
import { buildStoreVariant } from '../products';

const buildFavoriteItem = (
  favoriteItem: FavoriteItemFromApi,
  displaySettings?: StoreSettings,
) => {
  return {
    id: favoriteItem.id,
    variantId: favoriteItem.variant_id,
    favoriteListId: favoriteItem.favorite_list_id,
    variant: favoriteItem.variant
      ? buildStoreVariant(favoriteItem.variant, displaySettings)
      : null,
  };
};

export const buildFavorite = (
  favorite: FavoriteFromApi,
  displaySettings?: StoreSettings,
) => {
  return {
    id: favorite.id,
    name: favorite.name,
    customerId: favorite.customer_id,
    items: favorite.items.map((item) =>
      buildFavoriteItem(item, displaySettings),
    ),
  };
};
