import {
  AgentFromApi,
  CompanyFromApi,
} from '../../models/api/company-from-api';
import { ICompanyLight } from '../../models/view-models/company';
import { IAgentLight } from '../../models/view-models/agent';
import { buildAddress } from '../address';

export const buildCompany = (company: CompanyFromApi): ICompanyLight => {
  const shippingAddresses = company.shipping_addresses.map(buildAddress);
  shippingAddresses?.sort((a, b) => {
    if (a.isDefault === b.isDefault) {
      return (
        new Date(a.createdAt!).getTime() - new Date(b.createdAt!).getTime()
      );
    }
    return a.isDefault ? -1 : 1;
  });
  return {
    id: company.id,
    name: company.name,
    code: company.code,
    regionId: company.region_id,
    paymentTerms: company.payment_terms,
    paymentCurrencyCode: company.payment_currency_code,
    franco:
      typeof company.franco === 'number' ? company.franco / 100 : undefined,
    minOrderAmount: company.metadata?.min_order_amount
      ? +company.metadata.min_order_amount / 100
      : undefined,
    agent: company.agent ? buildAgent(company.agent) : null,
    shippingAddresses: shippingAddresses,
    billingAddress: buildAddress(company.billing_address),
    metadata: company.metadata,
  };
};

const buildAgent = (agent: AgentFromApi): IAgentLight => {
  return {
    id: agent.id,
    email: agent.email,
    firstName: agent.first_name,
    lastName: agent.last_name,
  };
};
