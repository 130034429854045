import axios, { AxiosRequestConfig } from 'axios';
import { QueryClient } from '@tanstack/react-query';

// Defaults to standard port for Medusa server
const MEDUSA_LOGOUT = 'https://auth.startcatalog.com/logout';
const MEDUSA_BACKEND_URL =
  process.env.NEXT_PUBLIC_MEDUSA_BACKEND_URL ||
  process.env.NEXT_PUBLIC_MEDUSA_LOCAL_BACKEND_URL;

const INTERCOM_APP_ID = process.env.NEXT_PUBLIC_INTERCOM_APP_ID || '';
const ENVIRONMENT = process.env.NEXT_PUBLIC_ENVIRONMENT || 'local';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 60 * 24,
      retry: 1,
    },
  },
});

const catalogClient = axios.create({ baseURL: MEDUSA_BACKEND_URL });
async function catalogRequest<T>(
  method: string,
  path = '',
  payload = {},
  params = {},
  headers = {},
) {
  let langParam: string;
  const storedLng = localStorage?.getItem('i18nextLng')?.split('-')[0];
  if (storedLng) {
    const langParamOperator = path.includes('?') ? '&' : '?';
    langParam = `${langParamOperator}lang=${storedLng}`;
  }
  const options: AxiosRequestConfig = {
    method,
    withCredentials: true,
    url: path + langParam,
    data: payload,
    headers,
    params,
  };
  return await catalogClient<T>(options);
}

export {
  MEDUSA_BACKEND_URL,
  MEDUSA_LOGOUT,
  INTERCOM_APP_ID,
  ENVIRONMENT,
  queryClient,
  catalogRequest,
  // setupAxiosInterceptors,
};
