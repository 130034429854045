import React, { createContext, useContext, useEffect, useState } from 'react';
import { v4 } from 'uuid';
import Toast from '../../modules/common/components/toast';
import { createPortal } from 'react-dom';

interface IToast {
  id: string;
  description?: string;
  variant: 'success' | 'error' | 'warning' | 'info';
  title: string;
}

type ToastOptionsProps = {
  description?: string;
  variant: 'success' | 'error' | 'warning' | 'info';
  title: string;
};

type ToastContextProps = {
  addToast: (options: ToastOptionsProps) => void;
};

const ToastContext = createContext<ToastContextProps | null>(null);

export const useToasts = (): ToastContextProps => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToasts must be used within a ToastProvider');
  }
  return context;
};
export const ToastProvider: React.FC = ({ children }) => {
  const [toasts, setToasts] = useState<IToast[]>([]);
  const [node, setNode] = useState<HTMLElement>(null);

  useEffect(() => {
    if (typeof document !== 'undefined') {
      setNode(document.body);
    }
  }, []);
  const addToast = (options: ToastOptionsProps) => {
    const id = v4();
    setToasts((prevToasts) => [
      ...prevToasts,
      {
        id,
        ...options,
      },
    ]);
  };

  const removeToast = (id: string) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };

  return (
    <ToastContext.Provider value={{ addToast }}>
      {node &&
        createPortal(
          <div className="fixed top-4 right-4 z-50 p-4">
            {toasts.length > 0 &&
              toasts.map((toast) => (
                <Toast key={toast.id} {...toast} handleClose={removeToast} />
              ))}
          </div>,
          node,
        )}
      {children}
    </ToastContext.Provider>
  );
};
