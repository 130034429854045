import { AddressFromApi } from '../../models/api/address-from-api';
import { CountryFromApi, RegionFromApi } from '../../models/api/cart-from-api';
import { IAddress, ICountry, IRegion } from '../../models/view-models/address';

export const buildAddress = (address: AddressFromApi): IAddress => {
  return address
    ? {
        id: address.id,
        firstName: address.first_name,
        lastName: address.last_name,
        address_1: address.address_1,
        address_2: address.address_2,
        city: address.city,
        countryCode: address.country_code,
        country: address.country?.display_name || null,
        postalCode: address.postal_code,
        phone: address.phone,
        isDefault:
          address.metadata && address.metadata.is_default
            ? Boolean(address.metadata.is_default)
            : false,
        metadata: address.metadata,
        createdAt: address.created_at ? new Date(address.created_at) : null,
        updatedAt: address.updated_at ? new Date(address.updated_at) : null,
      }
    : null;
};

export const buildCountry = (country: CountryFromApi): ICountry => {
  return {
    id: country.id,
    displayName: country.display_name,
    iso2: country.iso_2,
    iso3: country.iso_3,
    numCode: country.num_code,
    regionId: country.region_id,
    name: country.name,
  };
};

export const buildRegion = (region: RegionFromApi): IRegion => {
  return {
    id: region.id,
    createdAt: region.created_at ? new Date(region.created_at) : null,
    updatedAt: region.updated_at ? new Date(region.updated_at) : null,
    deletedAt: region.deleted_at ? new Date(region.deleted_at) : null,
    name: region.name,
    taxCode: region.tax_code,
    taxRate: region.tax_rate,
    currencyCode: region.currency_code,
    countries: region.countries?.map((country) => buildCountry(country)),
  };
};
