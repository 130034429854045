import React, { useContext, useEffect, useState } from 'react';
import { useCart } from '../hooks/use-cart';
import { RegionFromApi } from '../models/api/cart-from-api';
import { ICatalogCart } from '../models/view-models/cart';
import { buildCart } from '../utils/cart';
import { useCartDropdown } from './cart-dropdown-context';
import { useSettings } from '@lib/hooks/use-settings';
import { fetchMyCart } from '@lib/data/cart-data';
import { useCustomerCompany } from '@lib/hooks/use-customer-company';
import { useAccount } from './account-context';
import { useToasts } from './toaster-context';
import { useIsMutating } from '@tanstack/react-query';

interface VariantInfoProps {
  variantId: string;
  quantity: number;
  isPreorder: boolean;
}

interface LineInfoProps {
  lineId: string;
  quantity: number;
}

interface StoreContext {
  cart: ICatalogCart | null;
  setCart: (cart: ICatalogCart) => void;
  resetCart: () => void;
  totalItems: number;
  addLineItem: (item: VariantInfoProps) => Promise<void>;
  updateLineItem: (item: LineInfoProps) => Promise<void>;
  deleteItem: (lineId: string) => Promise<void>;
  isAddingItem: boolean;
}

const StoreContext = React.createContext<StoreContext | null>(null);

export const useStore = () => {
  const context = useContext(StoreContext);
  if (context === null) {
    throw new Error('useStore must be used within a StoreProvider');
  }
  return context;
};

interface StoreProps {
  children: React.ReactNode;
}

export const StoreProvider = ({ children }: StoreProps) => {
  const [cart, setCart] = useState<ICatalogCart>(null);
  const [totalItems, setTotalItems] = useState(0);
  const displaySettings = useSettings();
  const { company } = useCustomerCompany();
  const { customer } = useAccount();
  const { addToast } = useToasts();
  const mutationCount = useIsMutating();

  const { createNewCart, addItem, updateItem, removeItem } = useCart();

  const { timedOpen } = useCartDropdown();

  const getRegion = () => {
    if (company?.regionId) {
      return {
        regionId: company?.regionId,
        countryCode: company?.billingAddress?.countryCode,
      };
    }
    return null;
  };

  const ensureRegion = (region: RegionFromApi) => {
    const { regionId } = getRegion() || {
      regionId: region.id,
      countryCode: region.countries[0].iso_2,
    };

    if (regionId !== region.id) {
      console.error('region mismatch');
    }
  };

  const ensureCart = async () => {
    const savedCart = await fetchMyCart();
    if (savedCart) {
      setCart(buildCart(savedCart, displaySettings));
      ensureRegion(savedCart.region);

      const deletedItems = savedCart.items.filter((i) => !i.variant);
      if (deletedItems.length > 0) {
        addToast({
          description: `"${deletedItems
            .map((i) => i.description)
            .join('", "')}" plus disponibles`,
          variant: 'error',
          title: 'Error',
        });
        for (const item of deletedItems) {
          await deleteItem(item.id, savedCart.id);
        }
      }
    } else {
      const region = getRegion();
      await createCart(region?.regionId); // ou crée un nouveau panier
    }
  };

  const createCart = async (regionId: string) => {
    if (!customer) return;
    await createNewCart.mutateAsync(
      { region_id: regionId },
      {
        onSuccess: (cart) => {
          setCart(buildCart(cart, displaySettings));
          ensureRegion(cart.region);
        },
        onError: (error) => {
          if (process.env.NODE_ENV === 'development') {
            console.error(error);
          }
        },
      },
    );
  };

  const resetCart = async () => {
    setCart(undefined);
    await ensureCart();
  };

  useEffect(() => {
    if (customer && customer.hasAccount && !cart?.id) {
      ensureCart();
    }
  }, [customer]);

  useEffect(() => {
    if (cart) {
      setTotalItems(
        cart.items?.reduce((acc, item) => {
          if (item.displayUnit) {
            return acc + 1;
          } else {
            return acc + item.quantity;
          }
        }, 0) || 0,
      );
    }
  }, [cart]);

  const addLineItem = async ({
    variantId,
    quantity,
    isPreorder,
  }: {
    variantId: string;
    quantity: number;
    isPreorder: boolean;
  }) => {
    if (cart.isPreorder !== null && cart.isPreorder !== isPreorder) {
      console.warn(
        `Canot add item with isPreorder=${isPreorder} to cart with isPreorder=${cart.isPreorder}`,
      );
      return;
    }
    if (addItem.isLoading || mutationCount > 0) {
      return;
    }
    await addItem.mutateAsync(
      {
        id: cart.id!,
        payload: {
          variant_id: variantId,
          quantity: quantity,
        },
      },
      {
        onSuccess: (cart) => {
          setCart(buildCart(cart, displaySettings));
          timedOpen(variantId);
        },
        onError: (error: Error) => {
          console.error(error);
        },
      },
    );
  };

  const deleteItem = async (lineId: string, cartId?: string) => {
    if (removeItem.isLoading || mutationCount > 0) {
      return;
    }
    await removeItem.mutateAsync(
      {
        id: cartId || cart?.id,
        lineId,
      },
      {
        onSuccess: (cart) => {
          setCart(buildCart(cart, displaySettings));
        },
        onError: (error: Error) => {
          console.error(error);
        },
      },
    );
  };

  const updateLineItem = async ({
    lineId,
    quantity,
  }: {
    lineId: string;
    quantity: number;
  }) => {
    if (updateItem.isLoading || mutationCount > 0) {
      return;
    }
    await updateItem.mutateAsync(
      {
        id: cart?.id,
        lineId,
        quantity,
      },
      {
        onSuccess: (cart) => {
          setCart(buildCart(cart, displaySettings));
        },
        onError: (error: Error) => {
          console.error(error);
        },
      },
    );
  };

  const isAddingItem = addItem.isLoading;

  return (
    <StoreContext.Provider
      value={{
        cart,
        setCart,
        totalItems,
        addLineItem,
        deleteItem,
        updateLineItem,
        resetCart,
        isAddingItem,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
