import { QueryFunction } from '@tanstack/react-query';
import { catalogRequest } from '../config';
import {
  CartApiSchema,
  CartFromApi,
  ShippingOptionFromApi,
  UpdateCartReq,
} from '../models/api/cart-from-api';
import { OrderApiSchema, OrderFromApi } from '../models/api/order-from-api';
import axios, { AxiosResponse, HttpStatusCode } from 'axios';
import { tryGetOrderByCartId } from './order-data';
import { DuplicateOrderError } from 'src/types/global';

export type CartQueryParams = {
  id?: string;
};

export type CreateCartPayload = {
  region_id: string;
  sales_channel_id?: string;
  country_code?: string;
  items?: { variant_id: string; quantity: number }[];
  context?: {
    ip: string;
    user_agent: string;
  };
};

export type AddLineItemPayload = {
  variant_id: string;
  quantity: number;
  metadata?: Record<string, unknown>;
};

export const createCart = async (
  payload: CreateCartPayload,
): Promise<CartFromApi> => {
  const response = await catalogRequest<{ cart: CartFromApi }>(
    'post',
    'store/carts',
    payload,
  ).then((res) => {
    return res.data.cart;
  });
  const parsedResponse = CartApiSchema.parse(response);
  return parsedResponse;
};

export const updateCart = async ({
  id,
  payload,
}: {
  id: string;
  payload: UpdateCartReq;
}): Promise<CartFromApi> => {
  const response = await catalogRequest<{ cart: CartFromApi }>(
    'post',
    `store/carts/${id}`,
    payload,
  ).then((res) => res.data.cart);
  const parsedResponse = CartApiSchema.parse(response);
  return parsedResponse;
};

export const addShippingMethod = async ({
  id,
  payload,
}: {
  id: string;
  payload: { option_id: string; data?: Record<string, unknown> };
}): Promise<CartFromApi> => {
  if (!payload.option_id) throw new Error('Option ID is required.');
  const response = await catalogRequest<{ cart: CartFromApi }>(
    'post',
    `store/carts/${id}/shipping-methods`,
    payload,
  ).then((res) => res.data.cart);
  const parsedResponse = CartApiSchema.parse(response);
  return parsedResponse;
};

export const completeCart = async ({
  cartId,
  isReservation,
  idempotencyKey,
}: {
  cartId: string;
  isReservation: boolean;
  idempotencyKey?: string;
}): Promise<CartFromApi | OrderFromApi> => {
  let headers = {};
  if (idempotencyKey) {
    headers = {
      'idempotency-key': idempotencyKey,
    };
  }

  try {
    const apiResponse: AxiosResponse<{
      type: 'order' | 'cart';
      data: CartFromApi | OrderFromApi;
    }> = await catalogRequest(
      'post',
      `store/carts/${cartId}/complete`,
      {},
      {
        is_reservation: isReservation,
      },
      headers,
    );

    const response = apiResponse.data;
    let parsedResponse: CartFromApi | OrderFromApi;
    if (response.type === 'order') {
      parsedResponse = OrderApiSchema.parse(response.data);
    } else {
      parsedResponse = CartApiSchema.parse(response.data);
    }
    return parsedResponse;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      if (error.response.status === HttpStatusCode.Conflict) {
        console.warn(
          'Conflict error - cart may already be completed',
          error.response.status,
        );
        const order = tryGetOrderByCartId(cartId);
        if (order) return order;
      }

      if (error.response.status === HttpStatusCode.UnprocessableEntity) {
        const msg =
          error.response?.data?.message ||
          'Duplicate order error - a similar order already exists';
        console.warn(msg);

        throw new DuplicateOrderError(msg);
      }

      console.error('Error completing cart', error.response.status, { error });
      return Promise.reject(error);
    } else throw error;
  }
};

export const addLineItem = async ({
  id,
  payload,
}: {
  id: string;
  payload: AddLineItemPayload;
}): Promise<CartFromApi> => {
  const response = await catalogRequest<{ cart: CartFromApi }>(
    'post',
    `store/carts/${id}/line-items`,
    payload,
  ).then((res) => res.data.cart);
  const parsedResponse = CartApiSchema.parse(response);
  return parsedResponse;
};

export const updateLineItem = async ({
  id,
  lineId,
  quantity,
}: {
  id: string;
  lineId: string;
  quantity: number;
}): Promise<CartFromApi> => {
  const response = await catalogRequest<{ cart: CartFromApi }>(
    'post',
    `store/carts/${id}/line-items/${lineId}`,
    { quantity },
  ).then((res) => res.data.cart);
  const parsedResponse = CartApiSchema.parse(response);
  return parsedResponse;
};

export const deleteLineItem = async ({
  id,
  lineId,
}: {
  id: string;
  lineId: string;
}): Promise<CartFromApi> => {
  const response = await catalogRequest<{ cart: CartFromApi }>(
    'delete',
    `store/carts/${id}/line-items/${lineId}`,
  ).then((res) => res.data.cart);
  const parsedResponse = CartApiSchema.parse(response);
  return parsedResponse;
};

// Not used: Only one payment session is created at a time
export const selectPaymentSession = async ({
  id,
  provider_id,
}: {
  id: string;
  provider_id: string;
}): Promise<CartFromApi> => {
  const response = await catalogRequest<{ cart: CartFromApi }>(
    'post',
    `store/carts/${id}/payment-session`,
    { provider_id },
  ).then((res) => res.data.cart);
  const parsedResponse = CartApiSchema.parse(response);
  return parsedResponse;
};

export const createPaymentSessions = async ({
  id,
}: {
  id: string;
}): Promise<CartFromApi> => {
  const response = await catalogRequest<{ cart: CartFromApi }>(
    'post',
    `store/carts/${id}/payment-sessions`,
  ).then((res) => res.data.cart);
  const parsedResponse = CartApiSchema.parse(response);
  return parsedResponse;
};

export const getShippingOptions: QueryFunction<
  ShippingOptionFromApi[],
  ['shippingOptions', { cartId: string; cacheKey: string }]
> = async ({ queryKey: [, { cartId }] }) => {
  // cacheKey is only used to invalidate the query when it changes
  const response = await catalogRequest<{
    shipping_options: ShippingOptionFromApi[];
  }>('get', `store/shipping-options/${cartId}`);
  return response.data.shipping_options;
};

export const fetchMyCart = async (): Promise<CartFromApi | undefined> => {
  try {
    const response = await catalogRequest<{ cart: CartFromApi }>(
      'get',
      'store/customers/me/cart',
    );
    const parsedResponse = CartApiSchema.parse(response.data.cart);
    return parsedResponse;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      if (
        error.response.status === 404 ||
        error.response.status === 401 ||
        error.response.status === 403
      ) {
        return undefined;
      }
      console.error('Error fetching cart', error.response.status, { error });
      throw new Error('Error fetching cart');
    } else throw error;
  }
};
