import { useEffect, createContext, useContext } from 'react';
import { init, setGroup, setUserId, track } from '@amplitude/analytics-browser';
import { useSettings } from '@lib/hooks/use-settings';
import { ENVIRONMENT } from '@lib/config';

const AMPLITUDE_API_KEY = '65d5b76603c62c5f46f1abf3fa4b984f';

type AmplitudeContextType = {
  trackAmplitudeEvent?: (
    eventName: string,
    eventProperties?: Record<string, unknown>,
  ) => void;
  identifyAmplitudeUser?: (id: string) => void;
};
export const AmplitudeContext = createContext<AmplitudeContextType | null>(
  null,
);

export const AmplitudeContextProvider = ({ children }) => {
  useEffect(() => {
    if (ENVIRONMENT === 'prod' && typeof window !== 'undefined') {
      init(AMPLITUDE_API_KEY, undefined, {
        defaultTracking: {
          sessions: true,
          pageViews: true,
          formInteractions: true,
          fileDownloads: true,
        },
      });
    }
  }, []);

  const sellerName = useSettings().sellerNameTrimLow;

  const trackAmplitudeEvent = (
    eventName: string,
    eventProperties: Record<string, unknown>,
  ) => {
    if (ENVIRONMENT === 'prod' && typeof window !== 'undefined') {
      track(eventName, eventProperties);
    } else {
      console.log('Amplitude event', eventName, eventProperties);
    }
  };
  const identifyAmplitudeUser = (id: string) => {
    if (ENVIRONMENT === 'prod') {
      setUserId(id);
      setGroup('seller', sellerName);
    }
  };

  const value = { trackAmplitudeEvent, identifyAmplitudeUser };

  return (
    <AmplitudeContext.Provider value={value}>
      {children}
    </AmplitudeContext.Provider>
  );
};

export const useAmplitudeContext = () => {
  const context = useContext(AmplitudeContext);
  if (context === null)
    throw new Error(
      'useAmplitudeContext must be used within a AmplitudeContextProvider',
    );
  return context;
};
