import { z } from 'zod';

const BaseApiSchema = z.object({
  id: z.string().optional(),
  created_at: z.nullable(z.string()).optional(),
  updated_at: z.nullable(z.string()).optional(),
  deleted_at: z.nullable(z.string()).optional(),
});

export const ImageApiSchema = z.object({
  id: z.nullable(z.string()).optional(),
  url: z.nullable(z.string().url()).optional(),
});

export const VariantOptionApiSchema = BaseApiSchema.extend({
  value: z.nullable(z.string()).optional(),
  option_id: z.nullable(z.string()).optional(),
  variant_id: z.nullable(z.string()).optional(),
  option: z
    .nullable(
      z.object({
        title: z.nullable(z.string()).optional(),
        metadata: z.nullable(z.record(z.unknown())).optional(),
      }),
    )
    .optional(),
  metadata: z.nullable(z.record(z.unknown())).optional(),
});

export const ProductOptionApiSchema = BaseApiSchema.extend({
  title: z.nullable(z.string()).optional(),
  values: z.nullable(z.array(VariantOptionApiSchema)).optional(),
  product_id: z.nullable(z.string()).optional(),
  option_id: z.nullable(z.string()).optional(),
  metadata: z.nullable(z.record(z.unknown())).optional(),
});

export const PricesApiSchema = BaseApiSchema.extend({
  amount: z.nullable(z.number()).optional(),
  currency_code: z.nullable(z.string()).optional(),
  max_quantity: z.nullable(z.number()).optional(),
  min_quantity: z.nullable(z.number()).optional(),
  price_list_id: z.nullable(z.string()).optional(),
  region_id: z.nullable(z.string()).optional(),
  variant_id: z.nullable(z.string()).optional(),
});

export const VariantApiSchema = BaseApiSchema.extend({
  title: z.string().optional(),
  product_id: z.string().optional(),
  sku: z.nullable(z.string()).optional(),
  barcode: z.nullable(z.string()).optional(),
  ean: z.nullable(z.string()).optional(),
  inventory_quantity: z.nullable(z.number()),
  material: z.nullable(z.string()).optional(),
  weight: z.nullable(z.number()).optional(),
  length: z.nullable(z.number()).optional(),
  height: z.nullable(z.number()).optional(),
  width: z.nullable(z.number()).optional(),
  prices: z.nullable(z.array(PricesApiSchema)).optional(),
  metadata: z.nullable(z.record(z.unknown())).optional(),
  thumbnail: z.nullable(z.string()).optional(),
  images: z.nullable(z.array(ImageApiSchema)).optional(),
  options: z.nullable(z.array(VariantOptionApiSchema)).optional(),
  original_price: z.nullable(z.number()).optional(),
  calculated_price: z.nullable(z.number()).optional(),
  product: z
    .nullable(
      z.object({
        id: z.nullable(z.string()).optional(),
        title: z.nullable(z.string()).optional(),
        description: z.nullable(z.string()).optional(),
        handle: z.nullable(z.string().optional()),
        options: z.nullable(z.array(ProductOptionApiSchema)).optional(),
        metadata: z.nullable(z.record(z.unknown())).optional(),
        tags: z
          .nullable(
            z.array(
              z.object({
                value: z.string(),
              }),
            ),
          )
          .optional(),
      }),
    )
    .optional(),
});

export const CollectionApiSchema = BaseApiSchema.extend({
  title: z.nullable(z.string()).optional(),
  handle: z.nullable(z.string()).optional(),
  metadata: z.nullable(z.record(z.unknown())).optional(),
});

export const ProductApiSchema = BaseApiSchema.extend({
  title: z.string().optional(),
  subtitle: z.nullable(z.string()).optional(),
  description: z.nullable(z.string()).optional(),
  handle: z.nullable(z.string()).optional(),
  status: z
    .union([
      z.literal('draft'),
      z.literal('proposed'),
      z.literal('published'),
      z.literal('rejected'),
    ])
    .optional(),
  thumbnail: z.nullable(z.string()).optional(),
  weight: z.nullable(z.number()).optional(),
  width: z.nullable(z.number()).optional(),
  height: z.nullable(z.number()).optional(),
  length: z.nullable(z.number()).optional(),
  material: z.nullable(z.string()).optional(),
  external_id: z.nullable(z.string()).optional(),
  metadata: z.nullable(z.record(z.unknown())).optional(),
  variants: z.nullable(z.array(VariantApiSchema)).optional(),
  options: z.nullable(z.array(ProductOptionApiSchema)).optional(),
  images: z.nullable(z.array(ImageApiSchema)).optional(),
  tags: z
    .nullable(
      z.array(
        z.object({
          value: z.string(),
        }),
      ),
    )
    .optional(),
  total_variants: z.number().optional(),
});

export type ProductFromApi = z.infer<typeof ProductApiSchema>;
export type ProductOptionFromApi = z.infer<typeof ProductOptionApiSchema>;
export type PricesFromApi = z.infer<typeof PricesApiSchema>;
export type VariantFromApi = z.infer<typeof VariantApiSchema>;
export type VariantOptionFromApi = z.infer<typeof VariantOptionApiSchema>;
export type CollectionFromApi = z.infer<typeof CollectionApiSchema>;
export type ImageFromApi = z.infer<typeof ImageApiSchema>;
