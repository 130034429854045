import React, { ReactNode, useEffect, useState } from 'react';
import { Cancel, Check, InfoEmpty, WarningTriangle } from 'iconoir-react';
import clsx from 'clsx';

interface IToastStyles {
  bg: string;
  textColor: string;
  icon: ReactNode;
}

type ToastProps = {
  id: string;
  variant: 'success' | 'error' | 'warning' | 'info';
  description?: string;
  title: string;
  handleClose: (id: string) => void;
  duration?: number;
};

const Toast = ({
  variant = 'error',
  description,
  id,
  handleClose,
  title,
  duration = 7000,
}: ToastProps) => {
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const [styles, setStyles] = useState<IToastStyles>();

  useEffect(() => {
    // to implement with dedicated US
    switch (variant) {
      case 'success':
        setStyles({
          bg: 'bg-success-5',
          textColor: 'text-success-70',
          icon: (
            <Check className="text-success-70 h-4 w-4" aria-hidden="true" />
          ),
        });
        break;
      case 'warning':
        setStyles({
          bg: 'bg-orange-5',
          textColor: 'text-orange-50',
          icon: (
            <WarningTriangle
              className="text-orange-50 h-4 w-4"
              aria-hidden="true"
            />
          ),
        });
        break;
      case 'info':
        setStyles({
          bg: 'bg-blue-50',
          textColor: 'text-blue-500',
          icon: (
            <InfoEmpty className="text-blue-500 h-4 w-4" aria-hidden="true" />
          ),
        });
        break;
      case 'error':
      default:
        setStyles({
          bg: 'bg-error-5',
          textColor: 'text-error-70',
          icon: <Cancel className="text-error-70 h-4 w-4" aria-hidden="true" />,
        });
    }
  }, [variant]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
      setTimeout(() => handleClose(id), 500);
    }, duration);

    return () => clearTimeout(timer);
  }, [id]);

  return (
    <div
      className={clsx(
        'rounded-xl p-4 bg-white border border-grey2-10 shadow transition-opacity duration-500',
        {
          'opacity-0': !isVisible,
          'opacity-100': isVisible,
        },
      )}
    >
      <div className="flex gap-5">
        <div
          className={`flex-shrink-0 h-6 w-6 flex items-center justify-center rounded-full ${styles?.bg}`}
        >
          {styles?.icon}
        </div>
        <div>
          <h3 className="font-semibold text-grey2-90">{title}</h3>
          <p className="text-sm font-medium text-grey2-60">{description}</p>
        </div>
        <button onClick={() => handleClose(id)}>
          <Cancel className="h-5 w-5 text-grey2-60" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
};

export default Toast;
