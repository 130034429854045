export type FavoriteState = {
  favoriteProducts: Set<string>;
  favoriteLists: Record<string, string[]>;
};

// #region Actions

export enum FavoriteActions {
  SET_ALL_LISTS_AND_PRODUCT = 'SET_ALL_LISTS_AND_PRODUCT',
  ADD_PRODUCT_TO_LIST = 'ADD_PRODUCT_TO_LIST',
  REMOVE_PRODUCT_FROM_ONE_LIST = 'REMOVE_PRODUCT_FROM_ONE_LIST',
  REMOVE_PRODUCT_FROM_ALL_LISTS = 'REMOVE_PRODUCT_FROM_ALL_LISTS',
  DELETE_LIST = 'DELETE_LIST',
  CLEAR_LIST = 'CLEAR_LIST',
}

type SetAllListAndProductsAction = {
  type: FavoriteActions.SET_ALL_LISTS_AND_PRODUCT;
  payload: {
    favoriteProducts: Set<string>;
    favoriteLists: Record<string, string[]>;
  };
};

type AddProductToListAction = {
  type: FavoriteActions.ADD_PRODUCT_TO_LIST;
  payload: { listId: string; variantId: string };
};

type RemoveProductFromOneListAction = {
  type: FavoriteActions.REMOVE_PRODUCT_FROM_ONE_LIST;
  payload: { listId: string; variantIdToRemove: string };
};

type RemoveProductFromAllListsAction = {
  type: FavoriteActions.REMOVE_PRODUCT_FROM_ALL_LISTS;
  payload: { variantIdToRemove: string };
};

type DeleteListAction = {
  type: FavoriteActions.DELETE_LIST;
  payload: { listIdToRemove: string };
};

type ClearListAction = {
  type: FavoriteActions.CLEAR_LIST;
  payload: { listIdToClear: string };
};

export type FavoriteActionsType =
  | SetAllListAndProductsAction
  | AddProductToListAction
  | RemoveProductFromOneListAction
  | RemoveProductFromAllListsAction
  | DeleteListAction
  | ClearListAction;

// #endregion

// #region Reducer

export const favoriteReducer = (
  state: FavoriteState,
  action: FavoriteActionsType,
): FavoriteState => {
  switch (action.type) {
    case FavoriteActions.SET_ALL_LISTS_AND_PRODUCT:
      return {
        ...state,
        favoriteProducts: action.payload.favoriteProducts,
        favoriteLists: action.payload.favoriteLists,
      };

    case FavoriteActions.ADD_PRODUCT_TO_LIST: {
      const { listId, variantId: variantToAdd } = action.payload;
      return {
        ...state,
        favoriteProducts: state.favoriteProducts.add(variantToAdd),
        favoriteLists: {
          ...state.favoriteLists,
          [listId]: [...(state.favoriteLists[listId] || []), variantToAdd],
        },
      };
    }
    case FavoriteActions.REMOVE_PRODUCT_FROM_ONE_LIST: {
      const { listId: listIdToRemove, variantIdToRemove } = action.payload;
      const indexToRemove =
        state.favoriteLists[listIdToRemove]?.indexOf(variantIdToRemove);
      if (indexToRemove !== undefined && indexToRemove !== -1) {
        state.favoriteLists[listIdToRemove].splice(indexToRemove, 1);
      }

      const stillInAList = Object.values(state.favoriteLists).some((list) =>
        list.includes(variantIdToRemove),
      );

      if (!stillInAList) {
        state.favoriteProducts.delete(variantIdToRemove);
      }

      return {
        ...state,
        favoriteLists: { ...state.favoriteLists },
      };
    }
    case FavoriteActions.REMOVE_PRODUCT_FROM_ALL_LISTS: {
      const productIdToRemoveAll = action.payload.variantIdToRemove;
      for (const list of Object.values(state.favoriteLists)) {
        const index = list.indexOf(productIdToRemoveAll);
        if (index !== -1) {
          list.splice(index, 1);
        }
      }
      state.favoriteProducts.delete(productIdToRemoveAll);
      return {
        ...state,
        favoriteLists: { ...state.favoriteLists },
      };
    }

    case FavoriteActions.DELETE_LIST: {
      const { listIdToRemove: listIdToDelete } = action.payload;
      const productsToDelete = state.favoriteLists[listIdToDelete] || [];

      delete state.favoriteLists[listIdToDelete];

      productsToDelete.forEach((productId) => {
        const isInOtherList = Object.values(state.favoriteLists).some((list) =>
          list.includes(productId),
        );
        if (!isInOtherList) {
          state.favoriteProducts.delete(productId);
        }
      });

      return {
        ...state,
        favoriteProducts: new Set(state.favoriteProducts),
        favoriteLists: { ...state.favoriteLists },
      };
    }

    case FavoriteActions.CLEAR_LIST: {
      const { listIdToClear } = action.payload;
      const productsToClear = state.favoriteLists[listIdToClear] || [];

      productsToClear.forEach((productId) => {
        const isInOtherList = Object.values(state.favoriteLists).some((list) =>
          list.includes(productId),
        );
        if (!isInOtherList) {
          state.favoriteProducts.delete(productId);
        }
      });

      return {
        ...state,
        favoriteProducts: new Set(state.favoriteProducts),
        favoriteLists: {
          ...state.favoriteLists,
          [listIdToClear]: [],
        },
      };
    }
    default:
      return state;
  }
};

// #endregion
