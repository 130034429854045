import { z } from 'zod';
import { CustomerApiSchema } from './customer-from-api';

const BaseApiSchema = z.object({
  id: z.string().optional(),
  created_at: z.nullable(z.string()).optional(),
  updated_at: z.nullable(z.string()).optional(),
  deleted_at: z.nullable(z.string()).optional(),
});

export const AddressApiSchema = BaseApiSchema.extend({
  address_1: z.nullable(z.string()).optional(),
  address_2: z.nullable(z.string()).optional(),
  city: z.nullable(z.string()).optional(),
  company: z.nullable(z.string()).optional(),
  customer_id: z.nullable(z.string()).optional(),
  first_name: z.nullable(z.string()).optional(),
  last_name: z.nullable(z.string()).optional(),
  phone: z.nullable(z.string()).optional(),
  postal_code: z.nullable(z.string()).optional(),
  country_code: z.nullable(z.string()).optional(),
  province: z.nullable(z.string()).optional(),
  customer: z.nullable(CustomerApiSchema).optional(),
  metadata: z.nullable(z.record(z.unknown())).optional(),
  country: z
    .nullable(
      z.object({
        display_name: z.nullable(z.string()).optional(),
      }),
    )
    .optional(),
});

export const CreateAddressPayloadSchema = z.object({
  first_name: z.string().optional(),
  last_name: z.string().optional(),
  phone: z.string().optional(),
  company: z.string().optional(),
  address_1: z.string(),
  address_2: z.string().optional(),
  postal_code: z.string(),
  city: z.string(),
  country_code: z.string(),
  province: z.string().optional(),
  metadata: z.record(z.string(), z.unknown()).optional(),
});

export type AddressFromApi = z.infer<typeof AddressApiSchema>;
