import React, { createContext, useContext, useEffect, useState } from 'react';
import { useMeAccount } from '../hooks/use-account-me';
import { ICatalogCustomer } from '../models/view-models/customer';
import { useCustomerCompany } from '@lib/hooks/use-customer-company';
import { ICompanyLight } from '@lib/models/view-models/company';
import DisconnectedModal from '@modules/common/components/disconnected-modal';

export enum LOGIN_VIEW {
  SIGN_IN = 'sign-in',
  REGISTER = 'register',
}
interface AccountContext {
  customer: ICatalogCustomer;
  company: ICompanyLight;
  retrievingCustomer: boolean;
  refetchCustomer: () => void;
  handleLogout: () => void;
}
const AccountContext = createContext<AccountContext | null>(null);
interface AccountProviderProps {
  children?: React.ReactNode;
}
export const AccountProvider = ({ children }: AccountProviderProps) => {
  const { customer, isLoading, refetch: refetchCustomer } = useMeAccount();
  const { company } = useCustomerCompany();

  const [stateCustomer, setStateCustomer] = useState<
    ICatalogCustomer | undefined
  >();

  const [isDisconnectedModalOpen, setIsDisconnectedModalOpen] = useState(false);
  useEffect(() => {
    if (customer) {
      if (!stateCustomer) {
        setStateCustomer(customer);
      } else if (stateCustomer.id !== customer.id) {
        console.warn('customer session has changed');
        setIsDisconnectedModalOpen(true);
      }
    }
  }, [customer]);

  useEffect(() => {
    // check if the customer has changed
    const onFocus = () => {
      refetchCustomer();
    };
    window.addEventListener('focus', onFocus);
    return () => {
      window.removeEventListener('focus', onFocus);
    };
  }, []);

  const handleLogout = () => {};
  return (
    <AccountContext.Provider
      value={{
        retrievingCustomer: isLoading,
        refetchCustomer,
        customer,
        company,
        handleLogout,
      }}
    >
      {children}
      <DisconnectedModal
        isOpen={isDisconnectedModalOpen}
        company={company}
        customer={stateCustomer}
      ></DisconnectedModal>
    </AccountContext.Provider>
  );
};
export const useAccount = () => {
  const context = useContext(AccountContext);
  if (context === null) {
    throw new Error('useAccount must be used within a AccountProvider');
  }
  return context;
};
